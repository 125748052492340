
.WholeCrew {
    --break-point: var(--break-width);
    z-index: 1;
    position: relative;
    color: var(--text-color);
    justify-items: center;
    align-items: center;
    justify-content: center;

}


.WholeCrew::after {
    font-size: 0.9em;
    position: absolute;
    content: ' ';
    height: 8em;
    width: 8em;
    top:0;
    background: url(../../../global-style/stickers/remote-sticker.svg) no-repeat center;
    transform: translate(-16em, -6em) rotate(15deg);
    filter: var(--sticker-shadow);
    z-index: 2;
}



.WholeCrew article {
    justify-self: flex-end;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 2em;
    width: 24em;
}

.WholeCrew article h2 {
}

.WholeCrew article p {
}

.WholeCrew figcaption {
    opacity: 0;
}


.crew {
    transform: rotate(5deg);
    background: url(./party-pals.jpg) no-repeat 45%;
    background-size: cover;
    will-change: transform;

}

.crew::after {
    transform: translateX(-50%) rotate(5deg)!important;
}

@media screen and (max-width: 900px) {
    .WholeCrew article {
        width: unset;
        padding: 1.5em 6em 5em;
        text-align: center;
    }
    .WholeCrew::after {
        transform: translate(13em, -10em) rotate(15deg);
    }
}
