
.ReachOutForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}


.ReachOutForm::before {
    top:6em;
    left:-2em;
    position: absolute;
    z-index: -1;
    content: ' ';
    height: 100%;
    width: 100em;
    background-color: var(--peachy-pink-light);
    border-radius: 5em;
    transform: rotate(7deg);
}


@media screen and (max-width: 900px) {
    .ReachOutForm::before {
        height: 70em;
    }
}




.ReachOutForm form {
    position: relative;
    column-gap: 2em;
    row-gap: 0.5em;
    width:70%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: stretch;
}

.ReachOutForm button {
    margin-top: 2em;
    justify-self: flex-start;
}



.ReachOutForm form>span {
    grid-column: 1;
    position: absolute;
    bottom:0.5em;
    margin-left: 12em;
}

.invalid input, .invalid textarea {
    border: 2px solid var(--peachy-pink)!important;
}

.valid input, .valid textarea {
    border: 2px solid var(--peachy-purple)!important;
}



.phonePrefix {
    position: relative;
}

.phonePrefix::before {
    position:absolute;
    content: '+44';
    top:50%;
    z-index: 2;
    line-height: 1em;
    padding: 0 1em;
    color: var(--neutral-dark);
    transform: translateY(50%);
}

.phonePrefix>input {
    padding-left: 3.5em!important;
}

@media screen and (max-width: 900px) {
    .ReachOutForm form {
        padding: 0 1.5em;
        width: 100%;
        font-size: 1.2em;
        display: flex;
        flex-direction: column;
    }
    .ReachOutForm button {
        align-self: start;
    }


    .ReachOutForm form>span {
        padding: 1em 0;
        text-align: left;
        position: revert;
        margin: 0;
    }
}
