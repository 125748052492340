
.LessHoops {
    align-items: center;
    position: relative;
    direction: rtl;
}

.LessHoops>article {
    direction: ltr;
    justify-self: flex-start;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    row-gap: 1em;
    margin: 5em 6em 0 0;
    /*width: 24em;*/
}


.LessHoops figcaption {
    opacity: 0;
}


.peaches {
    transform: rotate(-5deg);
    background: url(./peaches.jpg) no-repeat center top;
    background-size: 170%;
}

.peaches::after {
    transform: translateX(-50%) rotate(5deg)!important;
}

@media screen and (max-width: 900px) {
    .LessHoops {
        row-gap: 5em;
    }
    .LessHoops article {
        margin:0;
        width: unset;
        padding: 0 3em ;
        text-align: center;
    }
}
