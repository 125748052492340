.HealthInsuranceSimplyDone::before {
    position: absolute;
    z-index: -1;
    content: ' ';

    transform-origin: top right;
    transform: rotate(-5deg);

    background-color: var(--peachy-pink);
    border-radius: 8vw;
    width: 150%;
    right: 5em;
    top:-3em;
    height: calc(100% - 7em) ;
    pointer-events: none;
}



.HealthInsuranceSimplyDone {
    will-change: transform;
    position: relative;
    row-gap: 5em;
    --break-point: 900px;
    direction: rtl;
    z-index: 0;
    justify-content: center;
    align-items: start;
    text-align: center;
    color: white;
}


.HealthInsuranceSimplyDone>* {
    direction: initial;
}


.HealthInsuranceSimplyDone a {
    background-color: var(--peachy-pink-pale);
}

.HealthInsuranceSimplyDone figcaption {
    opacity: 0;
}

.appScreenshot {
    font-size: 0.9em;
    will-change: transform;
    align-self: center;
    position: relative;
    transform: rotate(-5deg);
    top: -4em;
    left:1em;
}

.appScreenshot img {
    margin-top:2em;
    margin-bottom: 5em;

    border: 0.7em solid black;
    border-radius: 2em;
    height: 39em;
    width: 18em;
    filter: var(--pic-shadow)
}


.appScreenshot::after {
    position: absolute;
    left:0;
    background:
            url(../../../global-style/elements/chat-morning.png) no-repeat calc(50% - 7em) 0.2em,
            url(../../../global-style/elements/vgp-call.png) no-repeat calc(50% + 3em) 8em,
            url(../../../global-style/elements/plan-limits.png) no-repeat calc(50% - 3em) 16.5em,
            url(../../../global-style/elements/lesley-2.png) no-repeat calc(50% + 3em) 30em;
    will-change: transform;
    content: ' ';
    height: 100%;
    width: 100%;
    background-size: 15em, 20em, 17em, 20em;
    filter: var(--pullout-shadow);
}


.simplyDone {
    padding: 3em 3em 5em;
    justify-self: center;
    gap: 2em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
}


.simplyDone::before {
    position: absolute;
    content: ' ';
    height: 8em;
    width: 8em;
    top:-6.5em;
    right:6em;
    background: url(../../../global-style/stickers/brain-sticker.svg) no-repeat center;
    transform: rotate(20deg);
    filter: var(--sticker-shadow);
}

.simplyDone h1 {
    position: relative;
    max-width: 10em;
}

.simplyDone p::before {
    position: absolute;
    content: ' ';
    height: 8em;
    width: 8em;
    background: url(../../../global-style/stickers/sun-sticker.svg) no-repeat center;
    top:7em;
    right:-2em;
    filter: var(--sticker-shadow);
}



.simplyDone p {
    position: relative;
    max-width: 25em;
}

.simplyDone>p::after {
    position: absolute;
    content: ' ';
    height: 8em;
    width: 8em;
    top: 300%;
    left: 40%;
    background: url(../../../global-style/stickers/grin-sticker.svg) no-repeat center;
    filter: var(--sticker-shadow);
    transform: rotate(10deg);
}



.local {
    top: -5em;
    left:-3em
}

.virtual {
    left:3em
}

.virtual, .local {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 1em;
    text-align: center;
    align-items: center;
}


.virtual p, .local p {
    width: 26em;
}


.virtualGpScreenshot, .mapScreenshot {
    font-size: 0.9em;
    margin-top: 2em;
    height: 39em;
    width: 18em;
    border: 0.7em solid black;
    border-radius: 2em;
    filter: var(--pic-shadow)
}

.virtualGpScreenshot {
    background: url(./virtual-doc.jpg) no-repeat 54%;
    background-size: cover;
    will-change: transform;
}

.virtualGpScreenshot::after {
    position: absolute;
    border-radius: 50%;
    content: ' ';
    width: 3.5em;
    height: 3.5em;
    background: var(--peachy-pink) url(../../../global-style/icons/icons-black/phone.svg) no-repeat center;
    filter: invert() hue-rotate(180deg);
    background-size: 3em;
    top:85%;
    transform:  translateX(-150%) rotate(135deg);
}

.virtualGpScreenshot::before {
    position: absolute;
    border-radius: 50%;
    content: ' ';
    width: 3.5em;
    height: 3.5em;
    background: white url(../../../global-style/icons/icons-black/disable-mic.svg) no-repeat center;
    opacity: 0.5;
    background-size: 3em;
    top:85%;
    transform: translateX(50%);
}

.mapScreenshot {
    position: relative;
    background: url(../../../global-style/elements/map-results.png) no-repeat center;
    background-size: cover;
}

.local::after {
    position: absolute;
    content: ' ';
    height:100%;
    width:20em;
    transform: translate(-50%, -9em);
    background: url(./rosa-2.png) no-repeat bottom;
    background-size: contain;
    filter: var(--pic-shadow);
    left: 50%;
    top: 2.5em;
}



@media screen and (max-width: 900px) {
    .HealthInsuranceSimplyDone::before {
        right: 3em;
    }

    .local, .virtual {
        left:0;
    }

    .appScreenshot {
        font-size: 0.9em;
    }

    .virtualGpScreenshot, .mapScreenshot {
        font-size: 0.9em;
        transform: rotate(-5deg);
    }

    .simplyDone::before, .simplyDone>p::before, .simplyDone>p::after {
        display:none;
    }

    .local::after {
        transform: rotate(-5deg) translate(-50%, -10em) !important;
    }
}
