
.FAQs::before {
    z-index: -1;
    position: absolute;
    content: ' ';
    height: 70%;
    width: 100%;
    background: url(../../global-style/svg-blobs/bolt-peachy-green-light.svg) top no-repeat;
    background-size: contain;
    transform-origin: top;
    transform: translateX(2em) rotate(5deg) scale(1.2);
}


.FAQs {
    z-index: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center ;
    row-gap: 4em;
    background-color: var(--neutral-color);
    /*background-color: var(--peachy-pink-light);*/
    overflow-x: hidden;
}

.FAQs::after {
    font-size: 0.9em;
    position: absolute;
    content: ' ';
    height: 8em;
    width: 8em;
    background: url(../../global-style/stickers/peach-sticker.svg) no-repeat center;
    transform: translate(24em, 10em);
    filter: var(--sticker-shadow);
    z-index: 2;
}




.pageHeading::before {
    font-size: 0.9em;
    position: absolute;
    content: ' ';
    height: 8em;
    width: 8em;
    background: url(../../global-style/stickers/family-sticker.svg) no-repeat center;
    transform: translate(-25em,-5em) rotate(-10deg);
    filter: var(--sticker-shadow);
    z-index: 2;
}

.pageHeading::after {
    font-size: 0.9em;
    position: absolute;
    content: ' ';
    height: 8em;
    width: 8em;
    background: url(../../global-style/stickers/grin-sticker.svg) no-repeat center;
    transform: translate(-18em, 10em) rotate(-5deg);
    filter: var(--sticker-shadow);
    z-index: 2;
}






@media screen and (max-width: 900px) {
    .FAQs::after {
        transform: translate(10em, -5em);
    }

    .pageHeading {
        padding-bottom: 0;
    }

    .pageHeading::before {
        display: none;
    }

    .pageHeading::after {
        transform: translate(-14em, 8em) rotate(-5deg);
    }
}

.FeelingPeachy::before {
    position: absolute;
    content: ' ';
    height: 100%;
    width:100%;
    top: -90%;
    background-color: var(--peachy-pink);
}

.FeelingPeachy {
    position: relative;
    color: white;
    background-color: var(--peachy-pink);
    width: 100%;
    z-index: -2;
}


.FeelingPeachy a:first-child {
    background-color: var(--peachy-pink-pale);
    border-color: var(--peachy-pink-pale);
    color: white;
}





.stillCurious::before {
    z-index: -1;
    position: absolute;
    top: -85em;
    content: ' ';
    height: 100em;
    width: 100em;
    background-color: var(--peachy-purple);
    border-radius: 100%;
}

.stillCurious {
    z-index: -1;
    margin-top: 4em;
    position: relative;
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 2em;
}
