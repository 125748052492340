.Marker {
    display:block;
    /*background: url(../../../../../global-style/icons/icons-black/alt-location-pin.svg) no-repeat center;*/
    /*background-size: contain;*/
    height: 5em;
    width: 5em;
    opacity: 0;
    position: relative;
    transition: all 0.2s;
    font-size: 1em;
    filter: var(--card-shadow);
}

.Marker span {
    display: none;
    /*opacity: 0;*/
    /*transition: all 0.2s;*/
}


.Marker:hover span, .hovered span {
    position: absolute;
    display: block;
    left: 65%;
    top: 5%;
    /*opacity: 1;*/
    /*right: 0;*/
    overflow: visible;
    background-color: white;
    border-radius: 0.5em;
    border: 1px solid var(--neutral-dark);
    padding:0.2em 0.5em 0.2em 0.3em;
    z-index: 10;
    white-space: nowrap;
}

.Marker:hover, .hovered {
    font-size: 1.2em;
    fill: var(--peachy-pink)
}
