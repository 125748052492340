
.profileHeader {
    display: grid;
    grid-template: auto auto / auto 1fr;
    column-gap: 2em;
    row-gap: 0.3em;
    align-items: end;
}

.profileHeader img {
    grid-row: 1 / 3;
    height: 5em;
    border-radius: 50%;
}

.profileHeader svg {
    grid-row: 1 / 3;
    height: 5em;
}

.profileHeader ul {
    font-size: 0.8em;
    align-self: start;
    display: flex;
    opacity: 0.7;
    align-items: center;
    justify-content: flex-end;
    gap: 2em;
}

.profileHeader li {
    min-width: fit-content;
}

.profileHeader li:first-child {
    flex: 1;
}

.profileHeader h4 {
    font-size: 0.8em;
    font-weight: 600!important;
}





@media screen and (max-width: 900px) {
    .profileHeader img {
        grid-row: 1 / 3;
        height: 3em;
    }
}



.male {
    display: inline-block;
    background-image: url(../../../../../../global-style/icons/icons-black/mars.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1.4em 1.4em;
    height: 1.5em;
    width: 1.5em;
    position: relative;

}

.female {
    display: inline-block;
    background-image: url(../../../../../../global-style/icons/icons-black/venus.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1.4em 1.4em;
    height: 1.5em;
    width: 1.5em;
    position: relative;
    top: 0.1em;
}


.child {
    display: inline-block;
    background: url(../../../../../../global-style/icons/icons-black/child.svg) no-repeat center;
    height: 1.2em;
    width: 1.2em;
}
