@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800;900&display=swap');
@import './fontawesome-pro-6.0.0-web/css/all.css';

@font-face {
    font-family: 'Naoko';
    src: url('./naoko-06-extrabold-webfont.woff') format('woff');
}

:root {

    --app-height: 100vh;

    --max-width: 1400px;
    --break-width: 900px;
    --paper-color: #F5F7Fa;
    --peachy-pink: #FF5B65;
    --peachy-purple: #6D0E6F;
    --peachy-green: #33EFA9;

    --peachy-pink-light: #FFD7E5;
    --peachy-pink-pale: #FF9A9F;
    --peachy-purple-light: #D3B7D4;
    --peachy-green-light: #C9F9E7;

    --neutral-color: #f5f7fa;
    --neutral-dark: #B0B8C7;
    /*--neutral-dark: #c5c7ca;*/


    --body-font: 'Poppins', sans-serif;
    --display-font: 'Naoko', 'Montserrat', sans-serif;

    --card-shadow: drop-shadow(1px 2px 1px rgba(0, 0, 0, 0.1)) drop-shadow(2px 4px 3px rgba(0, 0, 0, 0.1));

    /*--button-focus: drop-shadow(0px 0px 5px var(--peachy-green));*/
    --button-focus: drop-shadow(0px 0px 5px var(--peachy-pink));
    --button-focus-light: drop-shadow(0px 0px 5px white);
    --card-shadow: drop-shadow(1px 2px 1px rgba(0, 0, 0, 0.1)) drop-shadow(2px 4px 3px rgba(0, 0, 0, 0.1));
    --modal-shadow: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.1)) drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.1));


    /*from app*/
    --text-color: #222222;
    --text-color-light: #888888;
    /*--text-color: #000000;*/

    --off-white: white;
    --body-background: black;

    --tape-gray: #eee5d0;
    --tape-edge-gray: #b7b49d;

    /*--tape-gray: #eee2d0;*/
    /*--tape-edge-gray: #b7b49d;*/

    --pic-shadow: drop-shadow(1px 2px 1px rgba(0, 0, 0, 0.5)) drop-shadow(2px 4px 3px rgba(0, 0, 0, 0.4));
    --pic-box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.2), 4px 8px 8px rgba(0, 0, 0, 0.2);

    --pullout-shadow: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.2)) drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.2));

    --sticker-shadow: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5));


    --popText: 1px 1px 1px white;


/*drop-shadow(6px 12px 5px rgba(0, 0, 0, 0.1)) drop-shadow(6px 12px 10px rgba(0, 0, 0, 0.1))*/

    --elevation-1: 0 1px 3px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.11);
    --elevation-2: 0 3px 6px rgba(0, 0, 0, 0.08), 0 3px 6px rgba(0, 0, 0, 0.11);
    --elevation-3: 0 10px 20px rgba(0, 0, 0, 0.11), 0 6px 6px rgba(0, 0, 0, 0.11);
    --elevation-4: 0 14px 28px rgba(0, 0, 0, 0.11), 0 10px 10px rgba(0, 0, 0, 0.11);
    --elevation-5: 0 19px 38px rgba(0, 0, 0, 0.15), 0 15px 12px rgba(0, 0, 0, 0.11);

    --heading-color: var(--gray-02);
    --pencil-color: #dddddd;

    --default-transition: 0.3s ease;
    --header-height: 5em;
    --bounce-curve: cubic-bezier(0.355, 1.650, 0.830, 0.830);
}



/*html {*/
/*    scroll-behavior: smooth;*/
/*    min-height: var(--app-height);*/
/*}*/

/*body {*/
/*    font-family: 'Poppins', sans-serif;*/
/*    background-color: var(--body-background);*/
/*    display: flex;*/
/*    min-height: var(--app-height);*/
/*    flex-direction: column;*/
/*    align-items: stretch;*/
/*    font-size: clamp(12px, 3vw, 20px);*/
/*}*/


/*@media screen and (min-width: 900px) {*/
/*    body {*/
/*        font-size: clamp(12px, 1.5vw, 20px);*/
/*    }*/
/*}*/



html {
    scroll-behavior: smooth;
}

html, body, root {
    min-height: var(--app-height);
}


body {
    font-family: var(--body-font);
    font-size: 14pt;
    background-color: var(--paper-color);
}

@media screen and (max-width: 600px) {
    body {
        font-size: 3.8vw !important;
    }
}



p {
    line-height: 1.5em;
}


:root {
    --header-line-height: 1.35em;;
}

h1 {
    font-weight: 900;
    font-size:3em;
    font-family: var(--display-font);
    text-transform: uppercase;
    line-height: var(--header-line-height)
    /*line-height: 0.8em*/
}

h2 {
    font-weight: 900;
    font-size: 2em;
    font-family: var(--display-font);
    text-transform: uppercase;
    line-height: var(--header-line-height)
    /*line-height: 0.9em*/
}

h3 {
    font-weight: 900;
    font-size: 1.5em;
    font-family: var(--display-font);
    text-transform: uppercase;
    line-height: var(--header-line-height)
    /*line-height: 0.8em*/

}

h4 {
    font-weight: 700 ;
    font-size: 1.5em;
    font-family: var(--body-font);
    line-height: var(--header-line-height)
}

h5 {
    font-weight: 700 ;
    font-size: 1.2em;
    font-family: var(--body-font);
    line-height: var(--header-line-height)
}

h6 {
    font-weight: 400 ;
    font-size: 1em;
    font-family: var(--body-font);
    line-height: var(--header-line-height)
}
