.BenefitLimit {
    display: flex;
    justify-content: center;
    font-size: 1.5em;
    font-weight: 600;
    position: relative;
    padding-top:2em!important;
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.BenefitLimit input {
    width: 8em;
    text-align: center;
}

.BenefitLimit button:hover {
    background-color: var(--peachy-pink);
    color: white;
}

.BenefitLimit legend {
    margin: 0 auto;
    font-size: 0.66em;
    top: 1em;
    font-weight: normal;
    position: absolute;
    background-color: transparent;
}


.BenefitLimit button {
    height: 1.2em;
    width: 1.2em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10em;
    border:1px solid var(--peachy-pink);
    color: var(--peachy-pink);
    transition: all 0.2s;
    /*background-color: var(--peachy-pink);*/
    /*color: white;*/
}

.BenefitLimit button:disabled {
    pointer-events: none;
    filter: grayscale(1);
    opacity: 0.5;
}


.annualLimitSelect {
    border: 1px solid transparent;
    padding: 0.2em 0.6em;
    border-radius: 1em;
}

.annualLimitSelect:hover {
    border: 1px solid var(--peachy-pink);
}


.BoSelector {
    position: absolute;
    filter: var(--card-shadow);
    z-index: 10!important;
}

.BoSelector ul {
    list-style: none;
    padding: 0.2em;
    margin: 0.1em;
}

.BoSelector ul li {
    padding: 0;
    text-align: center;
}
.BoSelector::before {
    top: -0.6em!important;
    left: 50%!important;
    transform: translateX(-50%) rotate(45deg)!important;
}
