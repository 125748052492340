
.OurOffice {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 8em auto 8em;
    gap:4em
}

.OurOffice h3 {
    margin-bottom: 1em;
}


.Map {
    border-radius: 1.5em;
    width: 35em;
    height: 20em;
    /*overflow: visible;*/
    box-shadow: var(--pic-box-shadow);
    border: 1px solid var(--neutral-dark);
    /*pointer-events: none;*/
    z-index: 1;
}
.Map>* {
    /*pointer-events: all;*/
}

.hq {
    display:block;
    background: url(../../../global-style/icons/icons-peachy-pink/peachy-pin.svg) no-repeat center;
    background-size: contain;
    will-change: transform;

    height: 4em;
    width: 4em;
}


@media screen and (max-width: 900px) {
    .OurOffice {
        font-size: 1.2em;
        padding: 0em 1.5em;
        align-items: start;
        flex-direction: column;
    }

    .Map {
        width: 100%;
    }
}
