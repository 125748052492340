.BrowseCare {
    position: relative;
    height: 100%;
    width: 100%;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 0!important;
    background-color: white;
    /*overflow: hidden;*/
}


.Filters {
    max-width: 50em;
}



.panel {
    background-color: white;
    border: 1px solid var(--neutral-dark);
    border-radius: 0.5em;
}
