.AppliedFilters {
    display: flex;
    column-gap: 0.5em;
    height: 2em
}

.AppliedFilters>li {
    background-color: var(--peachy-pink);
    border-radius: 10%;
    position: relative;
    width: 2em;
    height: 2em;
    color: transparent;
    filter: var(--card-shadow)
}

.AppliedFilters .VirtualAppointment {
    background: var(--peachy-pink) url(../../../../../global-style/icons/icons-white/remote-care.svg) no-repeat center;
}
.AppliedFilters .F2FAppointment {
    background: var(--peachy-pink) url(../../../../../global-style/icons/icons-white/profile.svg) no-repeat center;
}

.AppliedFilters .Male {
    background: var(--peachy-pink) url(../../../../../global-style/icons/icons-white/mars.svg) no-repeat center;
    background-size: auto 70%;
}

.AppliedFilters .Female {
    background: var(--peachy-pink) url(../../../../../global-style/icons/icons-white/venus.svg) no-repeat center;
    background-size: auto 70%;
}

.AppliedFilters .Pediatric {
    background: var(--peachy-pink) url(../../../../../global-style/icons/icons-white/child.svg) no-repeat center;
    background-size: auto 70%;
}

.AppliedFilters .labelled {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: var(--display-font);
}

.AppliedFilters .wide {
    padding: 0 1em;
    min-width: 2em;
    width: fit-content;
}
