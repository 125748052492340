.CategoryFilter input {
    position: relative;
    background-color: white;
    height: 100%;
    width: 100%;
    /*border: 1px solid var(--neutral-dark);*/
    /*border-radius: 0.2em;*/
    padding: 0 1em 0 4em;
}

.CategoryFilter input:focus {
    border-color: var(--peachy-pink);
    outline: 1px solid var(--peachy-pink);
}


.CategoryFilter {
    height:100%;
    position: relative;
}


.options {
    padding: 0.3em 0;
    top: calc(100% + 0.5em);
    /*top:3.5em;*/
    z-index: 10;
    filter: var(--card-shadow);
}



.CategoryFilter button {
    transition: all 0.3s;
    position: absolute;
    top: 50%;
    left: 0;
    color: transparent;
    height: 2.5em;
    width: 2.5em;
    transform: translate(30%, -50%);
    border-radius: 50%;
    background: url(../../../../../global-style/icons/icons-peachy-pink/search.svg) no-repeat center;
    background-size: 2.8em;
}

/*.CategoryFilter button:hover {*/
/*    background-color: var(--peachy-pink-light);*/
/*}*/
