.BuildQuote {
    background-color: var(--neutral-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0!important;

}

.BuildQuote>section {
    padding: 0 8em;
}

.benefitGroup>h6 {
    margin-top: 3em;
    grid-column: 1 / 3;
}


.benefitGroup {
    display: grid;
    grid-template: auto / 1fr 1fr;
    gap:1em;
    max-width: 1800px;
    width: 100%;
}

.docLink a, .docLink mark {
    color: var(--peachy-pink);
    background: none;
    cursor: pointer;
}


.LimitInfo {
}

.LimitFaq {
    top:5%!important;
    align-items: flex-start!important;
    row-gap: 2em;
    overflow: scroll;
}

.LimitFaq dl {
    display: grid;
    column-gap: 1em;
    grid-template: auto / 1fr 1fr;
    margin-bottom: 1em;
}
.LimitFaq cite {
    font-size: 0.8em;
}

.cancel {
    align-self: flex-end;
    font-size: 0!important;
}

.cancel::before {
    position: absolute;
    right: 1em;
    font-size: 1.5rem;
}



@media screen and (max-width: 1400px) {

    .BuildQuote>section {
        padding: 0 5%;
    }

    .benefitGroup>h6 {
        margin-top: 3em;
        grid-column: 1;
    }

    .benefitGroup {
        display: grid;
        grid-template: auto / 1fr!important;
        gap:1em;
    }

}





.FamilyInfo {
    background-color: white;
    border: 1px solid var(--neutral-dark);
    border-radius: 0.5em;
    padding: 2em 7em 2em 5em;
    max-width: 51em;
    display: flex;
    flex-direction: column;
    align-items: center;
    /*filter: var(--modal-shadow);*/
    position:relative;
    top: -10em
}


@media screen and (max-width: 600px) {
    .FamilyInfo {
        position: absolute!important;
        top: 0!important;
        height: 100%;
        padding: 2em 1em!important;
        width: 100%;
        border-radius: 0;
        border: none;
    }
    .advice {
        margin-top: 3em;
    }

    .LimitFaq {
        max-height: 100%
    }
}



.infoIcon::after {
    content: 'i';
    border: 2px solid;
    border-radius: 100%;
    width:1.5em;
    height:1.5em;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: 1em;
    cursor: pointer;
}


.backButton {
    font-size: 2.6em;
    justify-self: start;
}


.exitButton {
    cursor: pointer;
    color: var(--peachy-pink);
    font-size: 2em;
    justify-self: end;
}
