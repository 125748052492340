
.FaqPanel {
    background-color: white;
    filter: var(--pic-shadow);
    align-self: center;
    text-align: left;

    display: grid;
    grid-template: auto / 16em auto;
    grid-template-areas: 'HEADING FAQS' 'BLURB FAQS';

    align-items: baseline;

    row-gap: 2em;
    column-gap: 4em;
    width: 90%;
    border-radius: 1em;
    padding: 2em;
    line-height: 1.5em;
    will-change: scroll-position;
}

@media screen and (max-width: 900px) {
    .FaqPanel {
        grid-template: auto / 1fr!important;
        grid-template-areas: 'HEADING' 'BLURB' 'FAQS'!important;
        font-size: 1.1em;
        row-gap: 1em;
    }
}



.FaqPanel>h3 {
    grid-area: HEADING;
}

.FaqPanel ul {
    grid-area: FAQS;
    display: flex;
    flex-direction: column;
}

.FaqPanel article {
    grid-area: BLURB;
}


.FAQ:not(:last-child) {
    border-bottom: 1px solid var(--neutral-dark) ;
}


.FAQ {
    scroll-margin-top: var(--header-height);
    display: flex;
    flex-direction: column;
    position: relative;
}


.FAQ:last-child {}

.expanded::after {
    content: '-'!important;
}


.FaqPanel a {
    color: var(--peachy-pink);
}

.FAQ h4 {
    line-height: 2em;
    padding: 0.5em 0;
    cursor: pointer;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.FAQ>h4::after {
    /*position: absolute;*/
    padding: 0.5em 0 0.5em 0.5em;
    top: 0;
    right:0;
    font-size: 1.2em;
    content: '+';
}

.FAQ article {
    display: flex;
    flex-direction: column;
    row-gap: 1em;
}

.FAQ article>ul {
    padding-right: 1em;
    position: relative;
    list-style: initial;
    left: 1em;
    display: flex;
    gap: 0.5em;
    flex-direction: column;
}

.FAQ article>*:last-child {
    padding-bottom: 1em;
}

.FAQ article span {
    display: inline;
}

.FaqLink {
    color: var(--peachy-pink);
    cursor: pointer;
}
