.HowItWorks {
    background-color: var(--peachy-green-light);
    display: flex;
    flex-direction: column;
    row-gap: 5em;
    overflow-x: hidden;
}


.ItsEasy {
    padding-bottom: 0!important;
}



.ItsEasy::before {
    font-size: 0.9em;
    position: absolute;
    content: ' ';
    height: 8em;
    width: 8em;
    background: url(../../global-style/stickers/remote-sticker.svg) no-repeat center;
    transform: translate(-26em, -5em) rotate(-20deg);
    filter: var(--sticker-shadow);
}

.ItsEasy::after {
    font-size: 0.9em;
    position: absolute;
    content: ' ';
    height: 8em;
    width: 8em;
    background: url(../../global-style/stickers/hospital-sticker.svg) no-repeat center;
    transform: translate(25em, 2em) rotate(10deg);
    filter: var(--sticker-shadow);
}



.FeelingPeachy::before {
    position: absolute;
    content: ' ';
    height: 100%;
    width: 100%;
    top: -90%;
    background-color: var(--peachy-pink);
    z-index:-1;
}

.FeelingPeachy {
    z-index:0;
    position: relative;
    background-color: var(--peachy-pink);
    color: white;
}

.FeelingPeachy a:first-child {
    background-color: var(--peachy-pink-pale);
}

.FeelingPeachy a:last-child {
    display: none;
}

@media screen and (max-width: 900px) {

    .ItsEasy::before {
        font-size: 0.7em;
        transform: translate(-16em, -10em) rotate(-20deg);

    }
    .ItsEasy::after {
        font-size: 0.7em;
        transform: translate(19em, 7em) rotate(10deg);
    }


    .FeelingPeachy {
        padding: 8em 1.5em 5em;
        background-color: var(--peachy-pink);
        color: white;
    }
    .FeelingPeachy a:first-child {
        background-color: var(--peachy-pink-pale);
        border-color: var(--peachy-pink-pale);
        color: white;
    }
}
