.CrapNav {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: space-between;
}

.CrapNav ul {
    display: flex;
    column-gap: 2em;
}


.CrapNav ul li a {
    transition: all 200ms;
    display: flex;
}


.CrapNav ul li a:hover {
    color: var(--peachy-pink)
}


.CrapNav ul:last-of-type {
    justify-content: flex-end;
    align-items: center;
}

.signUpButton {
    padding: 0.6em 1.8em;
    border-radius: 0.6em;
    font-size: 1.1em;
}

.signUpButton:hover {
    background-color: white;
    border: 1px solid var(--peachy-pink);
    transform: none;
}
