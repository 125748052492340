.OurPlans::before {
    position: absolute;
    width: 85em;
    height:80em;
    z-index: -1;
    content: ' ';
    background: url(../../../global-style/svg-blobs/heart-peachy-green-light.svg) no-repeat top;
    will-change: transform;
    transform-origin: top center;
    transform: rotate(10deg);
    background-size: cover;
}


.OurPlans::after {
    font-size: 1rem;
    position: absolute;
    content: ' ';
    height: 8em;
    width: 8em;
    top:-15em;
    left: 40%;
    background: url(../../../global-style/stickers/hospital-sticker.svg) no-repeat center;
    transform: translate(-50%) rotate(-30deg);
    filter: var(--sticker-shadow);
}



.OurPlans {
    z-index: 1;
    position: relative;
    display: grid;
    padding: 2em;
    gap: 2em;
    justify-items: center;
    grid-template: auto auto auto / 1fr 1fr 1fr;
}

.benefit {
    position: relative;
    z-index: 10;
    width: 10em;
    height: 10em;
    background-color: white!important;
    border-radius: 1em;
    border: 2px solid var(--peachy-purple);
    filter: var(--card-shadow);
    transition: all 0.3s;
    cursor:pointer;
}


@media screen and (max-width: 900px) {
    .OurPlans:after {
        font-size: 0.8rem;
    }

    .OurPlans {
        padding: 0;
    }
    .benefit {
        font-size: 0.7em
    }
}


.benefit::before {
    position: absolute;
    content: ' ';
    background-color: var(--peachy-pink);
    width: 2.4em;
    height: 1.2em;
    top:0.8em;
    left:0.8em;
    border-radius: 0.6em ;
    transition: all 0.3s;
}

.benefit::after {
    position: absolute;
    content: ' ';
    background-color: white;
    width: 0.8em;
    height: 0.8em;
    margin: 0.2em;
    top:0.8em;
    left:2em;
    border-radius: 0.4em ;
    transition: all 0.3s;
}

.benefitOff {
    filter: brightness(1.1)!important;
    border: 2px solid var(--peachy-purple-light) !important;
}

.benefitOff.therapies {
    transform:  translate(0, 4em) rotate(30deg);
}

.benefitOff.consultations {
    transform: translate(0em, -2em) rotate(25deg) ;
}

.benefitOff.mental {
    transform: translate(0em, -5em) rotate(15deg);
}

.benefitOff.hospital {
    transform: translate(-1em, -2em) rotate(5deg);
}

.benefitOff.optical {
    transform:  translate(0em, -5em) rotate(-5deg);
}

.benefitOff.remote {
    transform: translate(1em, -2em) rotate(10deg);
}



.benefitOff::after {
    left:0.8em!important;
}

.benefitOff::before {
    background-color: black!important;
}




.benefit figcaption {
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: inherit;
    color: transparent;
    opacity: 0;
    transition: all 0.3s;
    pointer-events: none;
}

.benefitOff figcaption {
    opacity: 0.5;
}

.ourPlansArticle {
    position: relative;
    grid-column: 1 / 4;
    display: flex;
    row-gap: 1em;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.ourPlansArticle h2 {
    width: 10em;
}

.ourPlansArticle p {
    width: 26em;
}



.consultations {
    background: url(../../../global-style/stickers/consultations-sticker.grey.svg) no-repeat center;
    background-size: 80%;
    transform: translate(2em, -3em) rotate(5deg) ;
}

.hospital {
    background: url(../../../global-style/stickers/hospital-sticker.grey.svg) no-repeat center;
    background-size: contain;
    transform: translate(1em, -4em) rotate(-15deg);
}

.remote {
    background: url(../../../global-style/stickers/remote-sticker.grey.svg) no-repeat 70% 90%;
    background-size: 85%;
    transform: translate(0em, 0em) rotate(-10deg);
}

.mental {
    background: url(../../../global-style/stickers/mental-sticker.grey.svg) no-repeat center;
    background-size: contain;
    grid-row: 3;
    transform: translate(1em, -6em) rotate(-5deg);
}

.therapies {
    background: url(../../../global-style/stickers/therapy-sticker.grey.svg) no-repeat center;
    background-size: contain;
    grid-row: 3;
    transform:  translate(0, 2em) rotate(15deg);
}

.optical {
    background: url(../../../global-style/stickers/dental-optical-sticker.grey.svg) no-repeat center;
    background-size: contain;
    grid-row: 3;
    transform:  translate(-1em, -3em) rotate(10deg);
}
