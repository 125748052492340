
.PeachySelector {
    font-size: 0.9em;
    border: 2px solid var(--peachy-pink);
    background-color: white;
    position: absolute;
    border-radius: 0.5em;
    z-index: 0;
    cursor: pointer;
}

.PeachySelector::before {
    position:absolute;
    display: block;
    content: ' ';
    border-left: 2px solid var(--peachy-pink);
    border-top: 2px solid var(--peachy-pink);
    width: 1em;
    height: 1em;
    top: -0.6em;
    left: 1em;
    /*transform-origin: top left;*/
    transform: rotate(45deg);
    background-color: white;
}

.options {
    margin: 0 0.5em;
    outline: none;
    background-color: white;
    max-height: 10em;
}


.options>li {
    z-index: 2;
    padding: 0.4em 0;
}

.options>li>* {
    color: var(--text-color);
    display: block;
    padding:0.2em 0.5em;
    white-space: nowrap;
}

.options>li mark {
    color: white;
    background-color: var(--peachy-pink);
}
