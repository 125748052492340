.StartDateModal {
    background-color: white;
    border: 1px solid var(--neutral-dark);
    border-radius: 0.5em;
    padding: 2em 5em;
    max-width: 51em;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    row-gap: 1em;
}

.StartDateModal > h3::before,
.StartDateModal > h3::after {
    position: relative;
    vertical-align: middle;
    content: ' ';
    display: inline-block;
    height: 1em;
    width: 1em;
    background: url('../../../global-style/icons/icons-black/alarm-bell.svg') no-repeat center;
    background-size: cover;
}

.StartDateModal > h3::before {
    right: 1em
}

.StartDateModal > h3::after {
    left: 1em
}

.StartDateModal > p {
    width: 100%;
}

.cancel {
    align-self: flex-end;
}

.cancel::before {
    position: absolute;
    right: 1em;
    font-size: 1.5rem;
}