.PlaceLookup {
    position: relative;
    display: inline-block;
}

.options {
    margin-top: 0.2em;
    border: 2px solid var(--peachy-pink);
    background-color: white;
    border-radius: 0.5em;
    width: 100%;
    /*line-height: 2em;*/
}
.options li > * {
    display: block;
    padding: 0.2em 1em;
    height: 100%;
}

.options li mark {
    background-color: var(--peachy-pink);
    color:white;
}
