.datePicker {
    background-color: white;
    border: 1px solid var(--neutral-dark);
    border-radius: 1em;
    padding: 2em 7em;
    width: max-content;
    font-size: 0.75em;
    display: flex;
    flex-direction: column;
    align-items: center;
}
@media screen and (min-width: 600px) {
    .datePicker {
        filter: var(--modal-shadow);
    }
}


@media screen and (max-width: 600px) {
    .datePicker {
        padding: 2em;
        width: 100vw;
        border: none;
        border-radius: 0;
        filter: none;
    }
}


.datePicker h3 {
    text-align: center;
}

.datePicker * {
    user-select: none;
    filter: none;
}

.datePicker table {
    border-collapse: separate;
    border-spacing: 1.2em;
}

.datePicker table thead {
    transform: translate(0, 1em);
}

.datePicker table thead * {
    width:3.5em!important;
    font-weight: 500;
    text-transform: uppercase;
}

.datePicker table * {
    padding: 0.5em;
    text-align: center;
    vertical-align: middle;
}

.datePicker tbody>tr>td {
    height:3.5em;
    cursor: pointer;
    transition: all 0.2s;
    background-color: var(--neutral-color);
    border-radius: 10em;
}


.datePicker header {
    font-size: 1.5em;
    padding: 0 1em;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    justify-content: space-between;
    width:100%;
}

.datePicker nav {
    display: flex;
    align-items: center;
    width: 100%;
}

.datePicker nav>*{
    flex: 1;
    text-align: center;
}

.datePicker nav>*:first-child {
    cursor: pointer;
    transition: all 0.3s;
    opacity: 1;
    text-align: left;
}

.datePicker nav>*:last-child {
    cursor: pointer;
    transition: all 0.3s;
    opacity: 1;
    text-align: right;
}


.datePicker hgroup h3 {
    padding: 1em 1em 2em;
    font-size: 1em;
}


.monthYearSelector {
    position: absolute;
    font-weight: 600;
    width: 100%;
    top:6em;
    left:0;
    z-index: 2;
}

.monthYearSelector>ul {
    max-height:12em;
    display:inline-block;
    width: max-content;
    /*filter: var(--card-shadow);*/

}

.datePicker>button {
    font-size: 1.2em;
    margin: 1em;
}

.invalidMonthYearNudge {
    opacity: 0!important;
    cursor: auto!important;
}



.outOfMonth {
    opacity: 0.2;
}

.invalidDay {
    cursor: initial!important;
    opacity: 0.3;
    text-decoration: line-through;
}


.today {
    border: 1px solid var(--neutral-dark);
}
.weekday {
}
.weekend {
}


.cancel {
    align-self: flex-end;
    font-size: 0!important;
}

.cancel::before {
    position: absolute;
    right: 1em;
    font-size: 1.5rem;
}


.selected {
    background-color: var(--peachy-pink)!important;
    color: white;
    opacity: 1;
}

.datePicker i {
    font-size: 0;
}

.datePicker i::before {
    font-size: 2.5rem;
}
