
.CaptureForm {
    display: inline-flex;
    flex-direction: column;
    align-items: start;
    row-gap: 1.5em;
    max-width: 29em;
    padding: 2em;
    /*width: 25em;*/
}

.CaptureForm>legend>span {
    color: var(--peachy-pink);
    line-height: 4em;
}


.CaptureForm>mark::before {
    content: '!';
    border: 2px solid var(--peachy-purple);
    border-radius: 100%;
    min-width:1.5em;
    height:1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.CaptureForm>mark {
    display: flex;
    line-height: 1.5em;
    font-size: 0.8em;
    color: var(--peachy-purple);
    background-color: transparent;
    gap:1em;
    justify-content: space-between;
}

.CaptureForm>fieldset {
    align-self: stretch;
    display: flex;
    column-gap: 1em;
}


@media screen and (max-width: 600px) {
    .CaptureForm>fieldset {
        flex-direction: column;
        row-gap: 1em;
    }
}


.CaptureForm>button {
    align-self: center;

}

.CaptureForm a {
    color: var(--peachy-pink)
}

.CaptureForm>ul {
    list-style: disc outside;
    padding-left: 1em;
    color: var(--peachy-purple);
    font-size: 0.8em;
}
