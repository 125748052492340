.FilterExpander {
    transition: all 0.3s;
    color: transparent;
    position: relative;
    height: 100%;
    width: 3.5em;
    background: white url(../../../../../global-style/icons/icons-peachy-pink/alt-filter.svg) no-repeat center;
    background-size: 2.8em;
}

.FilterExpanderOpen {
    transform: rotate(180deg);
}

/*.FilterExpander:hover::before {*/
/*    transition: all 0.3s;*/
/*    content: ' ';*/
/*    position: absolute;*/
/*    border-radius: 50%;*/
/*    left:-50%;*/
/*    top:-50%;*/
/*    transform: translate(50%, 50%) scale(0.9);*/
/*    height: 100%;*/
/*    width: 100%;*/
/*    background-color: var(--peachy-pink-light);*/
/*    filter: */
/*}*/

/*.FilterExpander::before {*/
/*    transition: all 0.3s;*/
/*    content: ' ';*/
/*    position: absolute;*/
/*    border-radius: 50%;*/
/*    left:-50%;*/
/*    top:-50%;*/
/*    transform: translate(50%, 50%) scale(0.9);*/
/*    height: 100%;*/
/*    width: 100%;*/
/*    background-color: transparent;*/
/*}*/
