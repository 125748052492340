
.contact {
    display: contents;
    font-size: 0.8em;
    /*grid-column: 1/3;*/
    /*display: flex;*/
    /*justify-content: space-between;*/
    align-items: baseline;
    /*line-height: 1.5em;*/
}

.contactNameNumber {
    grid-column: 1;
    display: flex;
    flex-direction: column;
    color: var(--text-color);
}

.contactName {
}

.contactEmail {
    align-self: flex-start;
    grid-column: 2;
    display: flex;
    flex-direction: column;
}

