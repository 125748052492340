
.Slider {
    position: relative;
    --slider-ratio: 0;
    --slider-value: 0;
    --slider-min:0;
    --slider-max:0;
    height: 3em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    /*color: var(--peachy-purple);*/
    font-weight:bold;
}


.Slider::after {
    position: absolute;
    right:0;
    height: fit-content;
    text-align: center;
    content: attr(data-value) ' km';
}


.Slider input:focus {
    outline: red;
}

.Slider input {
    --thumb-size:0.7em;
    cursor: pointer;
    position: relative;
    height: 0.1em;
    min-height: 3px;
    border-radius: 99em;
    background: var(--neutral-dark) url(./track.svg) no-repeat left !important;
    background-size: calc(var(--slider-ratio)*100%) !important;
}
.Slider input::before, .Slider input::after {
    position: absolute;
    font-size: 0.6em;
    opacity: 0.5;
    width: fit-content;
    margin-top: 1em;
}

.Slider input::before {
    counter-reset: min var(--slider-min);
    content: counter(min)' km';
    transform: translateY(0%);
}

.Slider input::after {
    counter-reset: max var(--slider-max);
    content: counter(max)' km';
    left: 100%;
    transform: translate(-100%, -0%);
}



.Slider input::-webkit-slider-thumb {
    height:var(--thumb-size);
    width:var(--thumb-size);
    background: var(--peachy-pink);
    border-radius: var(--thumb-size);
}


.Slider input::-moz-range-thumb {
    height:var(--thumb-size);
    width:var(--thumb-size);
    background: var(--peachy-pink);
    border-radius: var(--thumb-size);
}
