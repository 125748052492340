.ActionPanel {
    display:flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1em;
    padding: 7em 0;
    text-align: center;
}


.ActionPanel p {
    width: 24em;
    padding-bottom: 2em;
}

.ActionPanel nav {
    display: flex;
    column-gap: 1em;
}

