.RipeIdea {
    text-align: center;
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2em;
    padding: 6em 4em 8em;
    z-index: 1;
}

.RipeIdea>p {
    width: 55%;
}


.Points {
    --scroll-y: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 9em;
    font-size: 1em;
    column-gap: 5em;
}

.Points li {
    display: flex;
    flex:1;
    align-items: center;
    flex-direction: column;
    row-gap: 1em;
    position: relative;
    z-index: 2;
}


.Points p {
    font-size: 0.8em;
    width: 18em;
}

.Points h4 {
    font-weight: 600;
}

.Points li::before, .Points li::after {
    position: absolute;
    top:0;
    transform: translateY(-130%);
    border-radius: 50%;
    display: inline-block;
    content: ' ';
    height: 6em;
    width: 6em;
}

.Points li::before {
    background-color: var(--peachy-purple);
    transition: background-color 0.3s;
}

.Points li::after {
    filter:invert();
    transform: translateY(-130%);
}

.Points li:hover::before {
    background-color: var(--peachy-pink);
}




.Points li:nth-child(1)::after {
    background: url(../../../global-style/icons/icons-black/savings.svg) no-repeat center;
    background-size: 80%;
}
.Points li:nth-child(2)::after {
    background: url(../../../global-style/icons/icons-black/lightning.svg) no-repeat center;
    background-size: 80%;
}
.Points li:nth-child(3)::after {
    background: url(../../../global-style/icons/icons-black/hand.svg) no-repeat center;
    background-size: 80%;
}



@media screen and (max-width: 900px){
    .RipeIdea {
        padding: 6em 1.5em 4em;
    }
    .RipeIdea h2 {
        padding: 0 1em;
    }
    .RipeIdea>p {
        width: 80vw;
    }

    .Points {
        flex-direction: column;
        row-gap: 4em;
        padding-top: 4em;
    }

    .Points p {
        font-size: 1em;
    }

    .Points li {
        align-items: flex-start!important;
        text-align: left;
        transform: translateX(20%);
    }

    .Points li::before, .Points li::after {
        top:50%;
        left:-40%;
        transform: translate(0, -50%);
        height: 5em;
        width: 5em;
    }
}
