.QuoteJourney {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 0!important;
    padding-bottom: 20%
}


.HeaderShadow {
    filter: var(--card-shadow);
}


.QuoteHeader {
    font-size: 0.8em;
    z-index: 10;
    position: sticky;
    background-color: white;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: var(--header-height);
    padding: 0 2em;
}
.QuoteHeader.theEnd {
    fill: white;
    background-color: var(--peachy-pink);
    color: white!important;
}

.QuoteNav {
    display: grid;
    grid-template: auto / 1fr 8fr 1fr;
    justify-items: center;
    align-items: center;
    width: 100%;
}


.QuoteNav button {
    color: var(--peachy-pink);
    display: flex;
    align-items: center;
}




.backButton {
    font-size: 2.6em;
    font-weight: 400!important;
    justify-self: start;
}

.exitButton {
    cursor: pointer;
    color: var(--peachy-pink);
    font-size: 2em;
    justify-self: end;
}

.QuoteNav>svg {
    grid-column: 2;
}

.pending {
    cursor: wait;
}
.pending * {
    opacity: 0;
}
