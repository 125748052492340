
.cardNumber {
    position: relative;
}

.cardNumber::after {
    position:absolute;
    height: 100%;
    top:0;
    right: 0;
    width: 4em;
    content: ' ';
}


.visa::after {
    background: url('./card-icons/visa.png') no-repeat center;
    background-size: contain;
}

.mastercard::after {
    background: url('./card-icons/mastercard.png') no-repeat center;
    background-size: contain;
}

.discover::after {
    background: url('./card-icons/discover.png') no-repeat center;
    background-size: contain;
}

.amex::after {
    background: url('./card-icons/american_express.png') no-repeat center;
    background-size: contain;
}



.cardField {
    width: 100%;
    border: 0.12em solid var(--neutral-dark);
    background-color: white;
    border-radius: 0.5em;
    padding: 0.5em 0.75em;
}

.cardFieldFocus {
    border-color: var(--peachy-pink);
}

/*.TextBox span {*/
/*    display: none;*/
/*}*/

/*.TextBox input::placeholder {*/
/*    color: var(--neutral-dark)*/
/*}*/

/*.TextBox input:focus {*/
/*    border-color: var(--peachy-pink)*/
/*}*/


.optIns {
    flex-direction: column;
    gap: 1em;
    overflow: visible;
}

.optIns label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.75em;
    gap: 0.5em;
}

.optIns label input:focus {
    filter: var(--button-focus)
}

.toggle {
    font-size: 0.6em!important;
}


.billingAddress {
    width:100%;
}
