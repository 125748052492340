/*.GetInsured::before {*/
/*    will-change: scroll-position;*/
/*    position: absolute;*/
/*    width: 100%;*/
/*    height:100%;*/
/*    z-index: -1;*/
/*    content: ' ';*/
/*    background: var(--peachy-pink) url(../../../global-style/svg-blobs/bolt-white-horizontal.svg) no-repeat top;*/
/*    transform: translateX(5em) scale(3.3) scaleX(-1) rotate(-5deg);*/
/*}*/

.GetInsured {
    will-change: scroll-position;
    margin: 2em 0 8em;
    position: relative;
    z-index: 0;
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.GetInsured article {
    position: relative;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.GetInsured>article::before {
    font-size: 1rem;
    position: absolute;
    content: ' ';
    left:10%;
    top: -5em;
    height: 7em;
    width: 7em;
    background: url(../../../global-style/stickers/consultations-sticker.svg) no-repeat center;
    transform: rotate(-20deg);
    filter: var(--sticker-shadow);
}

.GetInsured>article::after {
    font-size: 1rem;
    position: absolute;
    content: ' ';
    height: 8em;
    right: 10%;
    top:100%;
    width: 8em;
    background: url(../../../global-style/stickers/therapy-sticker.svg) no-repeat center;
    transform: rotate(15deg);
    filter: var(--sticker-shadow);
}



.GetInsured article>h2 {
    position:relative;
    width: 8em;
}

.GetInsured article>p {
    width: 27em;
}

.Steps {
    counter-reset: my-awesome-counter;
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: start;
    font-size: 1em;
    font-weight: bold;
    position: relative;
    top: 8em;
}

.Steps li {
    z-index: 10;
    position: relative;
    counter-increment: my-awesome-counter;
    width: 15em;
    padding: 0 2em;
    display: block;
    align-items: center;
}

.Steps li:not(:last-child)::after {
    content: '';
    display: block;
    position: relative;
    transform: translateX(7.5em);
    top: -5.2em;
    margin: 0 1em;
    border-bottom: 1px solid var(--neutral-dark);
}

.Steps li::before {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(-50%);
    left: 50%;
    top: -2.5em;
    border-radius: 5em;
    width: 2em;
    height: 2em;
    background-color: var(--peachy-purple);
    content: counter(my-awesome-counter) ' ';
    color: white;
    font-size: 2em;
    font-family: var(--display-font);
    transition: background-color 0.3s;
}

.Steps li:hover::before {
    background-color: var(--peachy-pink);
}



@media screen and (max-width: 900px) {
    .GetInsured {
        margin: 3em 0;
        transform: none;
    }
    .GetInsured p {
        padding: 0 1.95em;
    }
    .GetInsured::before {
        display: none;
    }

    .GetInsured article {
        transform: none;
    }

    .GetInsured article::before {
        top: -100%;
    }

    .GetInsured article::after {
        top: 90%;
    }

    .Steps {
        padding: 0;
        flex-direction: column;
        row-gap: 4em;
        top:7em;
        text-align: left;
        margin-bottom: 8em;
    }

    .Steps li {
        margin-left: 6em;
        display: flex;
    }

    .Steps li::after {
        display: none!important;
    }

    .Steps li::before {
        left: -1em;
        top:50%;
        transform: translate(-50%, -50%)
    }

    .Steps li:hover::before {
        background-color: var(--peachy-purple);
    }
    .Steps li::before {
        background-color: var(--peachy-pink);
    }
}
