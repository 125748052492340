.PeachyChoice {
    display: flex;
    justify-content: space-between;
    column-gap: 0.5em;
    position: relative;
    transition: all 0.2s;
    /*color: var(--peachy-purple);*/
    margin-top: 2em;
    white-space: nowrap;
}


.PeachyChoice span {
    font-weight: bold;
    position:absolute;
    z-index: 3;
    top:-2em;
    pointer-events: none;
}


.PeachyChoice input {
    pointer-events: all;
    cursor: pointer;
    appearance: none;
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.5em;
    padding:1em 1em 1em 2em;
    /*color:var(--peachy-purple-light);*/
    color: var(--neutral-dark);
    /*border: 1px solid var(--peachy-purple-light);*/
    border: 0.15em dashed var(--neutral-dark);
    outline: 0.15em solid transparent;

    transition: all 0.2s;
    background-repeat: no-repeat;
    background-position: 0.4em center;

    opacity: 0.5;

}

.PeachyChoice input::after {
    position: relative;
    content: attr(data-display-name) ' ';
}


.PeachyChoice input:checked {
    opacity: 1;
    /*background-color: var(--peachy-purple);*/
    border: 0.15em solid var(--peachy-green);
    /*outline: 0.12em solid var(--peachy-green);*/
    color: inherit;
}

