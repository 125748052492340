.Policies {
    background-color: var(--neutral-color);
    background-image: url(../../global-style/svg-blobs/circle-green-light.svg);
    background-repeat: no-repeat;
    background-size: 120%;
    background-position: 90% 2em;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    will-change: scroll-position;
    padding-bottom: 3em;
}



.faqPanel {
    grid-template: auto 1fr / 22em auto;
    grid-template-areas: 'HEADING FAQS' 'BLURB FAQS';
    margin-top: 3em;
}

.blurb {
    display: flex;
    align-self: start;
    flex-direction: column;
    row-gap: 2em;
    font-size: 0.8em;
}


.blurb ol {
    position: relative;
    list-style: initial;
    left: 1em;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    /*width: 15em;*/
}

.switcher {
    display: flex;
    justify-content: center;
    column-gap: 1em;
}

@media screen and (max-width: 900px) {
    .switcher a {
        width:max-content!important;
    }
}


.switcher a {
    transition: all 0.2s;
    font-size: 0.8em;
    border-radius: 0.5em;
    /*background-color: white;*/
    color: var(--peachy-purple);
    border: 1px solid var(--peachy-purple);

    width: 15em;
    padding: 1em;
    font-weight: bold;
    text-align: center;
}

.switcher a.current {
    color: white;
    border: none;
    background-color: var(--peachy-purple);
    filter: var(--card-shadow)
}


.FeelingPeachy {
    display:flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1em;
    padding: 8em 0;
    text-align: center;
}

.FeelingPeachy p {
    width: 24em;
    padding-bottom: 2em;
}
