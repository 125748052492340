
.JustGotPeachy::before {
    position: absolute;
    background-color: var(--peachy-pink);
    height:100%;
    width:100%;
    content: ' ';
    top: -90%;
}


.JustGotPeachy {
    position: relative;
    background-color: var(--peachy-pink);
    width: 100%;
    color: white;
    /*padding-top:0;*/
}


.JustGotPeachy a:first-child {
    background-color: var(--peachy-pink-pale);
    color: white;
}

.JustGotPeachy a:last-child {
    /*background-color: var(--peachy-purple);*/
    /*border-color: var(--peachy-purple);*/
}


.JustGotPeachy .heading p {
    width: 30em;
    max-width: 80vw;
}
.JustGotPeachy .heading h1 {
    max-width: 90vw;
    width: 10em;
}
