.Autocomplete {
    position: relative;
    display: inline-block;
}

.clickable:hover {
    cursor: pointer;
}

.hidden {
    display: none;
}

.choiceList {
    border: 2px solid var(--peachy-pink);
    background-color: white;
    border-radius: 0.5em;
    outline: none;
    height: 7em;
    transform: translateY(2.4em);
    min-width: 25em;
}

.choiceListItem {
    display: block;
    padding: 0.2em 1em;
}

.choiceListItem:hover {
    cursor: pointer;
    background-color: mistyrose;
}

.selectedChoiceListItem {
    background-color: var(--peachy-pink);
    color: white
}

.tag {
    display: inline-block;
    background-color: var(--peachy-green-light);
    padding: 0.5ch;
    border-radius: 0.5ch;
    margin-top: 0.5ch;
    margin-left: 0.5ch;
}
