.DragPane {
    height: 100vh;
    width: 100vw;
}


.DragPane[data-drag-state='draggable'] {
    cursor: grab;
}

.DragPane[data-drag-state='dragging'] {
    cursor: grabbing;
}

:global(.spacePressed) *[data-grabbed=true] {

}

