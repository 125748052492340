.PopupListSelector {
    z-index: 1;
    position: absolute;
}

.list {
    max-height: 10em;
    background-color: white;
    border: 1px solid lightgray;
    padding: 0.2em 0;
}

ul.placeholder {
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

p.placeholder {
    border-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    color: #B0B8C7;
    display: flex;
    justify-content: space-between;
    user-select: none;
}

p.placeholder > i {
    margin-top: 5px;
    color: black;
}

p.placeholder:hover {
    background-color: white;
    cursor: default
}

.list ul>li {
    padding: 0.2em;
}