.Tabs {
    width: 100%;
}

.Tabs>ul {
    display: flex;
    flex-direction: row;
}

.Tabs>ul>li {
    padding: 0.5em;
    cursor: pointer;
    background-color: white;
    border-radius: 0.5em 0.5em 0 0;
    opacity: 0.6;
    color: var(--text-color);
    border: 1px solid var(--pencil-color);
}


.Tabs>ul>li[data-is-selected=true] {
    border-bottom: none;
    opacity: 1;
}

.Tabs>div {
    padding: 1em;
    background-color: white;
    border: 1px solid var(--pencil-color);
    border-top:none;
    border-radius: 0 0 0.5em 0.5em;
}
