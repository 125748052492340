.WhatsNotCovered {
    background-color: white;
    grid-column: 1 / 3;
    display: flex;
    gap:3em;
    flex-direction: column;
    align-items: center;
    padding-top: 4em!important;
    padding-bottom: 4em!important;
    margin-top: 4em;
    width: 100%;
}

.WhatsNotCovered h2 {
    text-align: center;
}



.WhatsNotCovered ul {
    display: flex;
    flex-direction: column;
    row-gap: 1em;
    max-width: 1600px;
}



.subList {
    list-style: disc;
    margin-bottom: 0!important;
}

