.TransparentPricing {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    row-gap: 1em;
    padding: 2em 0;
    align-self: stretch;
}


.TransparentPricing p {
    width: 25em;
}


.Plans {
    counter-reset: plan-counter;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    list-style: none;
    width: 100%;
    column-gap: 2em;
    padding: 8em 3em 0 3em;
}


.Plans>li {
    flex: 1;
    position: relative;
    display: block;
    counter-increment: plan-counter;
}

.Plans>li::before {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(-50%);
    left: 50%;
    top: -3em;
    padding:0.2em 0.5em;
    width: fit-content;
    border-radius: 5em;
    background-color: var(--peachy-pink);
    content: 'PLAN ' counter(plan-counter);
    color: white;
    font-size: 1.5em;
    font-family: var(--display-font);
}

.Plans ul {
    text-align: left;
    display: flex;
    row-gap: 1em;
    flex-direction: column;
    justify-content: center;
}

.Plans ul>li {
    border-radius: 1em;
    background-color: white ;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    background-size: contain;
    height: 5em;
    font-size: 0.9em;
    padding-left: 6em;
    font-weight: bold;
    transition: all 0.2s;
    filter: var(--card-shadow)
}

.Plans ul>li:hover {
    transform: scale(1.1);
}

.Plans ul>li:nth-child(1) {
    background-image: url('../../../global-style/sticons/remote.svg');
}
.Plans ul>li:nth-child(2) {
    background-image: url('../../../global-style/sticons/mental-health.svg');
}
.Plans ul>li:nth-child(3) {
    background-image: url('../../../global-style/sticons/therapies.svg');
}
.Plans ul>li:nth-child(4) {
    background-image: url('../../../global-style/sticons/consultations-diagnostics.svg');
}
.Plans ul>li:nth-child(5) {
    background-image: url('../../../global-style/sticons/hospital-care.svg');
}




@media screen and (max-width: 900px) {
    .Plans {
        flex-direction: column;
        row-gap: 8em;
    }

    .TransparentPricing {
        padding: 5em 0;

    }
}
