.Filters {
    display: grid;
    grid-template: 3.5em auto auto auto / auto 1fr 1fr ;
    grid-template-areas: 'EXPANDER CATEGORY LOCATION ' 'PANEL PANEL PANEL' 'APPLIED APPLIED APPLIED' 'CATEGORIES CATEGORIES CATEGORIES';
    column-gap: 1em;
    justify-content: start;
    align-items: center;
    margin:1em;
    font-size: 0.8em;
    filter: var(--card-shadow);
    z-index: 1;
}

.Filters>ul:first-of-type{
    margin-top: 1em;
    grid-area: APPLIED;
}
.Filters>ul:last-of-type {
    margin-top: 1em;
    grid-area: CATEGORIES;
}

.Filters>section {
    grid-area: PANEL
}

.Filters>section>fieldset {
    margin-top: 1em;
}
