

.photo {
    position: relative;
    height: 30em;
    width: 25em;
    border: 1em solid white;
    border-bottom: 6em solid white;;
    /*filter: var(--pic-shadow);*/
    box-shadow: var(--pic-box-shadow)
}

.photo::after {
    position: absolute;
    display: block;
    content: ' ';
    height:3em;
    width: 8em;
    transform: translateX(-50%);
    left:50%;
    top:-2.5em;
    background-color: var(--tape-gray);
    border-right: 1px dotted var(--tape-edge-gray);
    border-left: 1px dotted var(--tape-edge-gray);
    opacity: 0.5;
    filter: none!important;
}



.buttonPalePink {
    background-color: var(--peachy-pink-pale) !important;
    color: white!important;
}


.disabledButton {
    background-color: var(--neutral-dark)!important;
    opacity: 0.5;
}

.button {
    padding: 0.75em 4em;
}

.button, .buttonSmall {
    color: white;
    background-color: var(--peachy-pink);
    border-radius: 0.5em;
    font-weight: bold;
    transition: all 200ms;
    cursor: pointer;
}

.buttonSmall {
    text-align: center;
    padding: 0.2em 0.5em;
}


.buttonBordered {
    border:2px solid var(--peachy-pink-pale)
}


.buttonFrame {
    color: var(--peachy-pink);
    border-radius: 0.5em;
    padding: 0.75em 4em;
    font-weight: bold;
    transition: all 200ms;
    cursor: pointer;
    border:2px solid var(--peachy-pink);
    background-color: white;
}


.button:not(.disabledButton):hover, .buttonFrame:not(.disabledButton):hover {
    filter: drop-shadow(1px 2px 1px rgba(0, 0, 0, 0.1)) drop-shadow(2px 4px 3px rgba(0, 0, 0, 0.1));
    transform: scale(1.05);
}

.button:not(.disabledButton):focus, .buttonFrame:not(.disabledButton):focus {
    filter: var(--button-focus);
    outline: none;
}

.buttonPalePink:not(.disabledButton):focus {
    filter: var(--button-focus-light);
}



.lookup {
    border: 2px solid var(--peachy-pink);
    background-color: white!important;
    border-radius: 0.5em;
    width: 100%;
}
.lookup li > * {
    display: block;
    padding: 0.2em 1em;
    height: 100%;
}

.lookup li mark {
    background-color: var(--peachy-pink);
    color:white;
}
