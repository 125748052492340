
.HeaderQuoteSection {
    width: 100%;
    justify-content: center;
    display: flex;
    background-color: white;
}


.HeaderQuote {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    font-size: 1.1em;
    padding-left: 1em;
    padding-right: 1em;
}


@media screen and (max-width: 600px) {
    .HeaderQuote {
        font-size: 1em;
    }
}


.HeaderQuote>*:first-child {
    padding-top: 2em;
}

.HeaderQuote p {
    max-width: 25em;
    text-align: center;
}

.HeaderQuote>a {
    /*width: 22em;*/
    padding:1em 2em;
    text-align: center;
}


.HeaderQuote p:last-of-type {
    font-size: 0.75em;
    font-weight: bold;
    padding-bottom: 1em;
}

.HeaderQuote p:last-of-type a {
    color: var(--peachy-pink)
}

.HeaderQuote mark {
    font-size: 0.8em;
    display: flex;
    column-gap: 1em;
    align-items: center;
    border-radius: 0.5em;
    border: 1px solid var(--peachy-purple);
    padding: 0.5em;
    background-color: white;
    color: var(--peachy-purple);
}

.HeaderQuote mark span {
    width: 20em;
    text-align: center;
}

.HeaderQuote mark svg {
    height: 4em;
    stroke: var(--peachy-purple);
    fill: var(--peachy-purple);
}
.HeaderQuote mark svg:first-of-type {
    transform: scaleX(-1);
}

.HeaderQuotePromo {
    display:flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.HeaderQuotePromo i {
    font-size: 0.7em;
    cursor: pointer;
}

.HeaderQuoteTermsBreakdown {
    background-color: white;
    border: 1px solid var(--neutral-dark);
    border-radius: 0.5em;
    padding: 2em 7em 2em 5em;
    max-width: 51em;
    display: flex;
    flex-direction: column;
    row-gap: 1em;
    align-items: center;
    position: relative;
    filter: var(--modal-shadow);
    margin: auto;
}

.cancel {
    align-self: flex-end;
    font-size: 0!important;
}

.cancel::before {
    position: absolute;
    top: 1em;
    right: 1em;
    font-size: 1.5rem;
}
