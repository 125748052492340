
.QuoteFooter {
    position: sticky;
    bottom: 0;
    background-color: var(--peachy-pink);
    color: white;
}


.QuoteFooter>section {
    padding: 1.2em 8em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
}

@media screen and (max-width: 1400px) {
    .QuoteFooter > section {
        font-size: 0.8em;
        padding-left: 5%;
        padding-right: 5%;
    }
}

.price {
    display:flex;
    flex-direction: column;
    cursor: pointer;
}




.priceLink {
}



.priceBreakdown {
    background-color: white;
    border: 1px solid var(--neutral-dark);
    border-radius: 0.5em;
    padding: 2em 7em 2em 5em;
    max-width: 51em;
    display: flex;
    flex-direction: column;
    row-gap: 1em;
    align-items: center;
    position: relative;
    filter: var(--modal-shadow);
    margin: auto;
}

.priceBreakdown table {
    margin: 1em 0;
    width: 95%;
}

.priceBreakdown table tr>* {
    padding: 0.25em;
    vertical-align: baseline;
}

.priceBreakdown table td:last-child {
    width: 40%;
    font-size: 0.7em;
    padding-left: 1em;
}

.priceBreakdown table td:nth-child(2) {
    text-align: right;
}

.priceBreakdown table th:nth-child(2) {
    white-space: nowrap;
    text-align: right;
}


.cancel {
    align-self: flex-end;
    font-size: 0!important;
}

.cancel::before {
    position: absolute;
    top: 1em;
    right: 1em;
    font-size: 1.5rem;
}

@media screen and (max-width: 600px) {
    .QuoteFooter > section {
        padding: 1em
    }

    .priceBreakdown {
        padding: 5em 1em
    }
}

.total {
    font-weight: bold;
}
