
.PlansGrow::before {
    position: absolute;
    width:120em;
    height: 100em;
    top: -12em;
    left:60%;
    z-index: -1;
    content: ' ';
    background-color: var(--peachy-pink-light);
    transform: translate(-50%);
    border-radius: 100%;
}


.PlansGrow {
    padding-left:4em;
    --break-point: 900px;
    background-size: contain;
    z-index: 1;
    position: relative;
}


.PlansGrow::after {
    font-size: 0.9em;
    position: absolute;
    content: ' ';
    top:0;
    height: 8em;
    width: 8em;
    background: url(../../../global-style/stickers/family-sticker.svg) no-repeat bottom;
    transform: translate(10em, -5em) rotate(5deg);
    filter: var(--sticker-shadow);
    z-index: 3;
}




.PlansGrow>article {
    display: flex;
    flex-direction: column;
    padding: 4em;
    row-gap: 1em;
}


.PlansGrow>article::after {
    font-size: 0.9em;
    position: absolute;
    content: ' ';
    top: 100%;
    height: 8em;
    width: 8em;
    background: url(../../../global-style/stickers/mental-sticker.svg) no-repeat bottom;
    transform: translate(15em, -6em);
    filter: var(--sticker-shadow);
    z-index: 3;
}




.PlansGrow article>h3:last-of-type {
    margin-top: 2em;
}



.PlansGrow figure {
    position: relative;
    transform: rotate(-5deg);
    background: url(./photo-board.jpg) no-repeat center;
    background-size: cover;
    will-change: transform;
}






@media screen and (max-width: 900px) {

    .PlansGrow::before {
        height: 165em;
        width: 160em;
        top: -4em;
    }

    .PlansGrow::after {
        transform: translate(12em, -13em);
        background: url(../../../global-style/stickers/sun-sticker.svg) no-repeat bottom;

    }

    .PlansGrow>article::after {
        left:50%;
        transform: translate(-50%, -1em) rotate(10deg);
    }

    .PlansGrow {
        margin-top: 5em;
        padding:1.5em;
        row-gap: 5em;
    }
    .PlansGrow article {
        text-align: center;
        padding: 2em;
    }


}
