.location {
    border-top: 1px solid var(--pencil-color);
}

.address {
    grid-column: 1;
    display: flex;
    flex-direction: column;
    font-size: 0.8em;
}


.addressLine {
    text-transform: capitalize;
}

.postcode {
    text-transform: uppercase;
    margin-bottom: 1em;
}


.locationName {
    grid-column: 1 / 3;
    display: flex;
    justify-content: space-between;
    color: var(--text-color);
    align-items: baseline;
    font-weight: 500!important;
    font-size: 0.8em;
    border-bottom: 1px solid var(--pencil-color);
}


.location:not(:first-of-type)::before {
    content: ' ';
    margin-top: 1em;
}



.locationName>span:last-child {
    opacity: 0.7;
    font-size: 0.8em;
}


.location {
    display: contents;
}



.phone {
    font-weight: 600;
    font-size: 0.9em;
    flex-direction: column;
}


.email {
    font-weight: 600;
    font-size: 0.9em;
    fill: var(--peachy-pink);
    background: url(paper-plane-top-light.svg) no-repeat center right;
    background-size: 1.3em;
    padding-right: 2em;
    transition: all 200ms;
    border-bottom: 1px solid transparent;
}

.email:hover {
    fill: var(--peachy-pink);
    background: url(paper-plane-top-solid.svg) no-repeat center right;
    background-size: 1.3em;
    border-bottom: 1px solid red;
}




.openingTimes {
    font-size: 0.7em;
    grid-column: 2;
    display: grid;
    column-gap: 2em;
    line-height: 1.7em;
    align-self: flex-start;
    justify-content: space-between;
}

.openingTimes dt {
    align-self: flex-start;
    grid-column: 1;
    font-weight: normal;
}

.openingTimes dd {
    grid-column: 2;
}
