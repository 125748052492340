
.Benefit {
    width: 65%;
    background-color: white;
    border-radius: 1em;
    text-align: left;
    filter: var(--card-shadow)
}

.Benefit p {
    padding: 1em 2em 2em;
}


.Benefit>h4 {
    padding: 0 1.5em 0 1em;
    display: flex;
    align-items: center;
    font-weight: 600;
}

.Benefit>h4::before {
    position:relative;
    vertical-align: middle;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    content: ' ';
    filter: opacity(0.5);
    display: inline-block;
    left:-0.5em;
    height: 4.5em;
    width: 5em;
}


.Benefit> h4.expanded::after {
    content: '-';
}

.Benefit>h4::after {
    font-size: 1.5em;
    margin-left: auto;
    content: '+';
}


@media screen and (max-width: 900px) {
    .Benefit {
        width:90%;
        /*font-size: 1.1em;*/
    }
}
