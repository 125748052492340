.Profile {
    display: flex;
    flex-direction: column;
    padding:0.7em 1em;
    min-width: 25em;
    font-size: 0.9em;
    line-height: 1.5em;
    filter: var(--card-shadow);
    transition: all 0.3s;
    transform: scale(1) translate(0);
    cursor: pointer;
}

.Profile h5 {
    white-space: nowrap;
    font-weight: normal;
    line-height: 2em;
    font-size: 0.7em;
}

.NoResults {
    font-size: 0.9em;
    margin: 1em;
    padding:0.7em 1em;
}
.NoResults img {
    filter: grayscale(1);
    opacity: 0.5;
}

.problem {
    align-self: flex-start;
    font-size: 0.8em;
}

.solution {
    opacity: 0.8;
    text-align: center;
    justify-self: center;
}

.Profile h4 {
    font-weight: normal;
    font-size: 1em;
}

.Profile aside {
    color: var(--text-color-light);
    font-weight: normal;
}


.Profile:hover {
    transform: translate(0.5em, -0.1em);
    /*border-color: var(--peachy-pink);*/
}

.Profile.fullProfile {
    transform: translate(1em, -0.1em);
    border-color: var(--peachy-pink);
}

.profileBody {
    display: flex;
    flex-direction: column;
}


.specialities {
    grid-column: 1/3;
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-start;
    gap: 0.5em
}


.locations {
    display: grid;
    column-gap: 2em;
    row-gap: 0.4em;
    justify-content: space-between;
    grid-template: auto / 3fr 4fr;
    align-items: baseline;
}

.locations>h5 {
    grid-column: 1/3;
    margin-top: 2em;
}


/*.Profile::after {*/
/*    content : '>';*/
/*    position: absolute;*/
/*    right: 0.5em;*/
/*    font-size: 2em;*/
/*}*/




@media screen and (max-width: 900px) {
    .Profile {
        min-width: unset!important;
    }


    .locations {
        display: flex;
        flex-direction: column;
        row-gap: 0.4em;
    }
}



.language {
    font-size: 0.8em;
    /*background-repeat: no-repeat;*/
    /*padding-left: 3em;*/
    /*background-position: left center;*/
    /*background-size: 2em;*/
}


.gmc {
    font-size: 0.8em;
    color: var(--text-color);
}


.prices {
    display: flex;
    gap: 1em
}

.prices>dt {
    white-space: nowrap;
    border-radius: 0.3em;
    border: 1px solid var(--peachy-purple-light);
    padding: 0 1em;
    gap:1em;
    display: flex;
}

.toggle {
    color: var(--neutral-dark);
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    font-size: 2em;
}

.email {
    background-color: aliceblue;
}
