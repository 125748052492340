
.optIns {
    flex-direction: column;
    gap: 1em;
    overflow: visible;
}

.optIns label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.75em;
    gap: 0.5em;
}

.optIns label input:focus {
    filter: var(--button-focus)
}

.toggle {
    font-size: 0.6em!important;
}
