.PaediatricSelector {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*color: var(--peachy-purple);*/
    font-weight: bold;
    align-self: flex-start;
    cursor: pointer;
}

@media screen and (max-width: 900px) {
    .PaediatricSelector input {
        font-size: 0.6em;
    }
}
