.CapturePassword {
    position: relative;
}

.CapturePassword button:focus {
    filter: var(--button-focus);
}

.CapturePassword button {
    position: absolute;
    right: -1em;
}

@media screen and (max-width: 600px) {
    .CapturePassword {
        width:90%
    }
}

.showPassword {
    font-size:0;
    color: var(--peachy-pink);
}

.showPassword::before {
    position: absolute;
    font-size:1.5rem;
}


.signingUp {
    background-color: white;
    opacity: 0.5;
    cursor: wait;
}
.signingUp * {
    opacity: 0;
}
