/*.PlentyOfPeach::before {*/
/*    position: absolute;*/
/*    content: ' ';*/
/*    z-index: -1;*/
/*    height: 100%;*/
/*    width: 100%;*/
/*    background: url(../../../global-style/svg-blobs/bolt-white.svg) no-repeat top;*/
/*    transform: translate(6em, 15em) scale(8, 5) rotate(15deg);*/
/*}*/

.PlentyOfPeach {
    margin: 10em 0 12em;
    z-index: 0;
    position: relative;
    text-align: center;
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2em;
    will-change: transform;
}

.PlentyOfPeach>p {
    width: 25em;
}

.PlentyOfPeach>h2 {
    width: 11em;
}


.Points {
    --scroll-y: 1;
    z-index: 1;
    list-style: none;
    display: flex;
    width: 80%;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 8em;
}

.Points li {
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    row-gap: 1em;
    position: relative;
    margin: 0 2em;
}

.Points p {
    font-size: 0.8em;
}

.Points h4 {
    font-weight: bold;
}

.Points li::before, .Points li::after {
    position: absolute;
    top:0;
    transform: translateY(-130%);
    border-radius: 50%;
    display: inline-block;
    content: ' ';
    height: 6em;
    width: 6em;
}


.Points li::before {
    background-color: var(--peachy-pink);
    transition: all 0.3s;
}

.Points li::after {
    transform: translateY(-130%);
    /*transform: translateY(-130%) rotate(var(--scroll-y));*/
    background-position: center;
    filter:invert();
}

.Points li:hover::before {
    background-color: var(--peachy-purple);
}



.Points li:nth-child(1)::after {
    background: url(../../../global-style/icons/icons-black/lightning.svg) no-repeat center;
    background-size: 80%;

}
.Points li:nth-child(2)::after {
    background: url(../../../global-style/icons/icons-black/hand.svg) no-repeat center;
    background-size: 80%;

}
.Points li:nth-child(3)::after {
    background: url(../../../global-style/icons/icons-black/remote-care.svg) no-repeat center;
    background-size: 80%;

}


@media screen and (max-width: 900px) {

    .PlentyOfPeach {
        margin: 3em 0;
    }

    .Points {
        flex-direction: column;
        row-gap: 4em;
        padding-top: 4em;
    }
    .Points p {
        font-size: 1em;
    }

    .Points li {
        align-items: flex-start!important;
        text-align: left;
        padding-left: 5em;
    }

    .Points li::before, .Points li::after {
        top:50%;
        left: -2em;
        transform: translate(0, -50%);
        height: 5em;
        width: 5em;
    }
}
