

.Believe::before {
    content: ' ';
    height: 100%;
    width: 100%;
    position: absolute;
}



.Believe {
    position: relative;
    padding-top: 2em;
    padding-bottom: 1em;
}



.Believe p {
    width: 25em;
    max-width: 90vw;
}
