.Footer {
    display: grid;
    row-gap: 1em;
    column-gap: 0;
    grid-template-areas: 'LOGO SOCIAL' 'NAV1 NAV2';
    grid-template-columns: 1fr auto;
    padding: 1em 2.5em 3em;
    color: white;
    font-size: 0.8em;
    background-color: black;
}
.Footer>nav {
    padding-top:1em;
}

.FooterForGetCare {
    grid-template-areas: 'LOGO SOCIAL' 'NAV2 NAV2';
    padding: 1em 2.5em 1em;
}


@media screen and (max-width: 900px){
    .Footer {
        grid-template-areas: 'LOGO' 'NAV1' 'NAV2' 'SOCIAL';
        padding: 2em 1.5em 3em;
        font-size: 1em;
    }

    .FooterForGetCare {
        grid-template-areas: 'LOGO NAV2' 'LOGO SOCIAL';
        padding: 1em;
        row-gap: 0em;
        /*align-items: flex-end;*/
    }

    .SecondaryLinksForGetCare {
        border-top: none!important;
        font-size: 0.8em;
        padding: 0.5em 0!important;
        justify-self: right;
        border-bottom: 1px solid white;
    }

    .SocialLinksForGetCare {
        border-top: none!important;
        column-gap: 2em;
        justify-self: flex-end;
        font-size: 0.9em!important;
        padding: 0.5em 0!important;
    }

    .logo {
        align-self: start!important;
        margin: 0!important;
    }


    .PrimaryLinks {
        flex-direction: column;
        gap: 1em!important;
    }
    .SocialLinks {
        border-top: 1px solid white;
        column-gap: 2em;
        justify-content: start!important;
        font-size: 1.2em;
    }
}

.SocialLinks {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-area: SOCIAL;
    min-width: 10em;
}

.SocialLinks a:not([href]) {
    opacity: 0.3;
}


.PrimaryLinks {
    border-top: 1px solid white;
    display: flex;
    column-gap: 3em;
    row-gap: 1em;
    grid-area: NAV1;
    padding-bottom: 2em;
}

.SecondaryLinks {
    color: var(--neutral-dark)!important;
    border-top: 1px solid white;
    display: flex;
    flex-direction: column;
    gap: 1em;
    grid-area: NAV2;
    padding-bottom: 2em;
}

.SecondaryLinksForGetCare {
    flex-direction: row;
    padding: 0;
}

.logo {
    align-self: end;
    width: 8em;
    fill:white!important;
    grid-area: LOGO;
}
