.PersonalPlans::before {
    position: absolute;
    left: -15em;
    width:80em;
    height: calc(100% - 2em);
    z-index: -1;
    content: ' ';
    background-color: var(--neutral-color);
    border-radius: 5em;
    transform: rotate(5deg);
}


.PersonalPlans {
    padding-bottom: 10em;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    row-gap: 2em;
}



.PersonalPlans>h2 {
    margin-top:3em;
    width: 14em;
}
.PersonalPlans>h3 {
    width: 15em;
    margin-top: 2em;
}

.PersonalPlans>p {
    width: 55%;
    margin-bottom: 2em;
}




.HospitalCare>h4::before {
    background-image: url('../../../global-style/sticons/hospital-care.svg');
}
.ConsultationDiagnostics>h4::before {
    background-image: url('../../../global-style/sticons/consultations-diagnostics.svg');
}
.MentalHealth>h4::before {
    background-image: url('../../../global-style/sticons/mental-health.svg');
}
.RemoteCare>h4::before {
    background-image: url('../../../global-style/sticons/remote.svg');
}
.Therapies>h4::before {
    background-image: url('../../../global-style/sticons/therapies.svg');
}
.DentalOptical>h4::before {
    background-image: url('../../../global-style/sticons/optical-dental.svg');
}
