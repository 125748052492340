.GenderSelector input {
    background-position: 1em center!important;
    filter: grayscale(1);
}

.GenderSelector input:checked {
    filter: none;
}

.GenderSelector input:first-of-type {
    background-image: url(../../../../../global-style/icons/icons-peachy-pink/mars.svg);
    background-size: 1.4em;
}

.GenderSelector input:last-of-type {
    background-image: url(../../../../../global-style/icons/icons-peachy-pink/venus.svg);
    background-size: 1.1em;
}

/*.GenderSelector input:first-of-type:checked {*/
/*    background-image: url(../../../../../global-style/icons/icons-peachy-pink/mars.svg);*/
/*}*/

/*.GenderSelector input:last-of-type:checked {*/
/*    background-image: url(../../../../../global-style/icons/icons-peachy-pink/venus.svg);*/
/*}*/

/*@media screen and (max-width: 900px) {*/
/*    .GenderSelector input {*/
/*        background: none!important;*/
/*        padding:1em!important;*/
/*        min-width: unset!important;*/
/*    }*/
/*}*/
