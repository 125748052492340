.HappyPeople {
    --break-point: 750px;
    --column-gap: 4em!important;
    padding: 0 5em;
    align-items: center;
    justify-items: center;
}


.HappyPeople article {
    display: flex;
    flex-direction: column;
    align-content: center;
    row-gap: 1em;
}

.HappyPeople article h3 {
    margin-top: 0.5em;
}

.photo {
    background: url(../LessHoops/peaches.jpg) no-repeat center top;
    background-size: 170%;
    transform: rotate(5deg);
}


@media screen and (max-width: 900px){
    .photo {
        top: 5em;
    }
}
