.GenderInput {
}

.GenderInput input {
    appearance: none;
}
.GenderInput label {
    display: block;
    background-color: white;
    text-align: center;
    width: 100%;
    border: 0.12em dashed var(--neutral-dark);
    border-radius: 0.5em;
    padding: 0.75em;
    color: var(--neutral-dark);
    opacity: 0.5;
}

.GenderInput label:focus-within {
    border: 0.12em solid var(--peachy-pink)!important;
    /*filter: var(--button-focus)*/
}

.selected {
    opacity: 1!important;
    border: 0.12em solid var(--peachy-green)!important;
    color: var(--text-color) !important;
}
