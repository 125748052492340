.SamplePlanLink {
    display: inline-block;
    color: var(--peachy-pink);
    cursor: pointer;
    position: relative;
}

.disabled {
    pointer-events: none;
    cursor: none;
    position: relative;
}


.disabled::before {
    display: inline-block;
    position: absolute;
    content: ' ';
    width: 100%;
    height: 100%;
    background-color: var(--peachy-pink);
    animation: pulse 1s infinite;
    border-radius: 10em;
}



@keyframes pulse {
    0% {
        transform: scale(1.1, 1);
        opacity: 0.2;
    }
    100% {
        transform: scale(1.3, 1.5);
        opacity: 0;
    }
}
