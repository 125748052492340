.Exclusion {
    background-color: var(--neutral-color);
    border-radius: 0.5em;
}


.Exclusion h6 {
    padding: 1em 2em;
    cursor: pointer;
    position: relative;
    font-weight: 700;
}


.Exclusion h6::after {
    font-size: 2em;
    content: '+';
    font-weight: 400!important;
    cursor: pointer;
    position: absolute;
    right: 1em;
}

.Exclusion h6.expanded::after {
    content: '-'!important;
}
.Exclusion article ul {
    list-style: disc;
    row-gap: 0;
}

.Exclusion article>ul {
    padding: 0 2em 2em 4em;
    display: inline-block;
    column-count: 3;
    column-gap: 2em;
    column-fill: balance;
    line-height: initial;
    font-size: 0.8em;
}


.Exclusion article>span {
    display: inline-block;
    font-size: 0.8em;
    padding: 0 2.5em 1em;
}

@media screen and (max-width: 600px) {
    .Exclusion article>ul {
        column-count: 1;
    }
}
