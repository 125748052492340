.Photo {
    position: relative;
    height: 30em;
    width: 25em;
    border: 1em solid white;
    border-bottom: 6em solid white;;
    box-shadow: var(--pic-box-shadow)
}

.Photo::after {
    position: absolute;
    display: block;
    content: ' ';
    height:3em;
    width: 8em;
    transform: translateX(-50%) rotate(5deg)!important;
    left:50%;
    top:-2.5em;
    background-color: var(--tape-gray);
    border-right: 1px dotted var(--tape-edge-gray);
    border-left: 1px dotted var(--tape-edge-gray);
    opacity: 0.5;
    filter: none!important;
}

@media screen and (max-width: 900px) {
    .Photo {
        height: 24em;
        width: 20em;
    }
}

.Photo figcaption {
    opacity: 0;
}
