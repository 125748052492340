
.FreeToGo::before {
    position: absolute;
    width:120em;
    height: 100em;
    z-index: -1;
    top: -20em;
    content: ' ';
    background-color: white;
    border-radius: 100%;
}


.FreeToGo {
    --break-point: 850px;
    justify-content: space-evenly;
    padding: 0 1.5em;
    align-items: center;
    z-index: 1;
    row-gap:3em;
    position: relative;
    direction: rtl;
}


.FreeToGo::after {
    font-size: 0.9em;
    position: absolute;
    content: ' ';
    height: 8em;
    width: 8em;
    background: url(../../../global-style/stickers/remote-sticker.svg) no-repeat center;
    transform: translate(27em, -15em) rotate(10deg);
    filter: var(--sticker-shadow);
}


.FreeToGo>article {
    direction: ltr;
    max-width: 80vw;
    display: flex;
    flex-direction: column;
    row-gap: 1em;
    padding: 6em 2em;
}


.FreeToGo>article::after {
    font-size: 0.9em;
    position: absolute;
    content: ' ';
    height: 8em;
    width: 8em;
    background: url(../../../global-style/stickers/dental-optical-sticker.svg) no-repeat center;
    transform: translate(10em, 25em) rotate(10deg);
    filter: var(--sticker-shadow);
}




.FreeToGo article>h3:last-of-type {
    margin-top: 2em;
}


.FreeToGo figcaption {
    opacity: 0;
}


.astronaut {
    transform: rotate(5deg);
    background: url(./space-man.jpg) no-repeat 40%;
    background-size: cover;
    will-change: transform;
}

.astronaut::after {
    transform: translateX(-50%) rotate(5deg)!important;
}


@media screen and (max-width: 900px) {
    .FreeToGo::before {
        top: -40em;
        height:160em;
    }
    .astronaut {
    }

    .FreeToGo::after {
        top: -8em;
        left: 1em;
        transform: rotate(10deg);
    }
    .FreeToGo>article::after {
        top: 8em;
        right:5em;
        transform: translate(0, 25em) rotate(30deg);
    }

    .FreeToGo>article {
        padding: 0 0 4em !important;
    }
}
