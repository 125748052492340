.TeamHealth {
    background-color: var(--neutral-color);
    /*background-color: var(--peachy-purple-light);*/
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 5em;
    overflow-x: hidden;
}

.TeamHealth::before {
    font-size: 0.9em;
    position: absolute;
    content: ' ';
    height: 8em;
    width: 8em;
    background: url(../../global-style/stickers/wink-sticker.svg) no-repeat center;
    transform: translate(-28em,0em) rotate(15deg);
    filter: var(--sticker-shadow);
    z-index: 2;
}


.TeamHealth::after {
    font-size: 0.9em;
    position: absolute;
    content: ' ';
    height: 8em;
    width: 8em;
    background: url(../../global-style/stickers/mental-sticker.svg) no-repeat center;
    transform: translate(24em, 7em) rotate(15deg);
    filter: var(--sticker-shadow);
    z-index: 2;
}


.betterHealth {
    z-index: 1;
    position: relative;
    will-change: transform;
}

.betterHealth h1 {
    max-width: 90vw;
}

.betterHealth::before {
    position: absolute;
    top: -4em;
    width:65em;
    height:65em;
    z-index: -1;
    content: ' ';
    background-color: var(--peachy-green-light);
    border-radius: 5em;
    transform: rotate(-10deg);
}


@media screen and (max-width: 900px) {
    .betterHealth::before {
        top:1em;
        right:-1em;
        height:70em;
        transform: rotate(-5deg);
    }

    .TeamHealth::before {
        transform: translate(-12em, 20em) rotate(15deg);
    }
    .TeamHealth::after {
        transform: translate(12em, -5em) rotate(15deg);
    }
}
