.LocationSelector {
    position: relative;
    height:100%;
}

.LocationSelector input {
    background-color: white;
    height: 100%;
    width: 100%;
    /*border: 1px solid var(--peachy-purple);*/
    border: var(--neutral-dark);
    /*border-radius: 0.2em;*/
    padding: 0 1em 0 4em!important;
}

.LocationSelector input::placeholder {
    /*color: var(--peachy-purple-light);*/
}

.LocationSelector input:focus {
    border-color: var(--peachy-pink);
    outline: 1px solid var(--peachy-pink);
}


.LocationSelector button {
    transition: all 0.3s;
    position: absolute;
    color: transparent;
    top: 50%;
    left: 0.5em;
    transform: translateY(-50%);
    /*line-height: 100%;*/
    width: 3em;
    height:3em;
    background: url(../../../../../global-style/icons/icons-peachy-pink/locate.svg) no-repeat center;
    background-size: 2.8em;
    border-radius: 50%;
}

.LocationSelector button:hover {
    background-color: var(--peachy-pink-light);
}


.options {
    left: 0;
    padding: 0.3em 0;
    top: calc(100% + 0.5em);
    /*top:3.5em;*/
    z-index: 1!important;
    filter: var(--card-shadow);
    /*left: calc(100% + 1em);*/
}
