.AddFamily {
    display: flex;
    row-gap: 1em;
    flex-direction: column;
}

@media screen and (max-width: 900px) {
    .AddFamily:last-child {
        margin-top: 2em
    }
}




.AddFamily h3 {
    font-size: 1em;
    font-weight: 700;
    cursor: pointer;
}

.AddFamily h3, .AddFamily li {
    background-color: white;
    border-radius: 0.5em;
    width: 100%;
    padding: 1em 1.5em;
    position: relative;
}


.AddFamily h3.canAdd::after {
    font-family: var(--body-font) !important;
    font-size: 2em;
    content: '+';
    font-weight: 400 !important;
    cursor: pointer;
    position: absolute;
    right: 0.75em;
}

.AddFamily h3.expanded::after {
    content: '-'!important;
}


.AddFamily li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    width: 100%;
    border: 0.12em solid white;
    cursor: context-menu;
}

.AddFamily li:hover {
    border: 0.12em solid var(--peachy-pink)!important;
}

.AddFamily li i {
    opacity: 0.3;
    position: absolute;
    display: flex;
    right:1em;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 1.2em;
    cursor: pointer;
    height: 1.5em;
    width: 1.5em;
    border-radius: 1em;
}

.AddFamily li i:hover {
    cursor: pointer;
    opacity: 0.7;
    background-color: var(--neutral-dark);
}
