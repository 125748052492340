.FormattedPrice {
    font-family: var(--display-font);
    text-transform: uppercase;
    display: inline-block;
    transition: all 300ms;
    filter: blur(0);
    opacity: 1;
    transform: scale(1);
}

.FormattedPrice.pending {
    opacity: 0;
    filter: blur(0.5em);
    transform: scale(1.2);
}


.FormattedPrice>span:first-child {
    font-size: 2.5em;
}

.FormattedPrice>span:nth-child(2) {
    font-size: 1.5em;
}

.beforeDiscount {
    text-decoration: line-through;
    margin-right: 1.5em;
}

