
.Modal {
    cursor: wait;
    pointer-events: all;
    background-color: transparent;
    z-index: 100;
}


.Spinner {
    display: flex;
    justify-content: center;
    align-items: center;
}
