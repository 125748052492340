
.ListSelector::-webkit-scrollbar {display: none;}
.ListSelector {-ms-overflow-style: none; scrollbar-width: none;}


.ListSelector {
    display: flex;
    flex-direction: column;
    justify-content: start;
    overflow-y: scroll;
    scroll-behavior: smooth;
}

.listItem:hover {
    background-color: #EFEFEF;
    cursor: pointer;
}

.listItemSelected {
    background-color:#DDDDDD;
}


