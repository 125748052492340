:root {
    --column-gap: 0em;
    --break-point: 0px;
}

.OneTwoColumnGrid {
    column-gap: var(--column-gap);
    display:grid;
    width: 100%;
    grid-template-columns: REPEAT(auto-fit, MINMAX(MAX(34%, MIN(100%, (CALC(VAR(--break-point) / 2) - (VAR(--column-gap) / 2)))), 1fr));
}


