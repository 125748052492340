
.ResultMap {
    position: absolute!important;
    height: 100%;
    width: 100%;
}


.range {
    fill: #FF5B651F;
    /*fill: #6D0E6F1F;*/
    stroke: var(--peachy-pink);
    stroke-width: 2px;
    overflow: visible;
}
