.BenefitCard {
    background-color: white;
    display: grid;
    grid-template: auto 1fr / auto 1fr;
    row-gap:2em;
    font-size: 0.8em  ;
    border: 3px solid var(--peachy-green);
    border-radius: 1em;
    padding: 2em 2em 2em 0;
    transition: 0.2s all;
    align-items: start;
    position: relative;
    top: 0;
}


@media screen and (min-width: 600px) {
    .BenefitCard:hover {
        border-style: solid;
    }
}


@media screen and (max-width: 600px) {
    .BenefitCard {
        grid-template: auto 1fr / 1fr auto 1fr;
        padding: 1em 2em;
        column-gap: 1em;
        justify-content: start!important;
        align-items: center;
    }

    .BenefitCard section {
        grid-column: 1 / 4!important;
    }
    .BenefitCard fieldset {
        grid-column: 1 / 4!important;
    }

    .BenefitCard::before {
        transform: translateX(1em) scale(1.2);
        grid-column-start: 3!important;
        grid-row-start: 1!important;
        height: 4em!important;
        width: 4em!important;
        justify-self: end;
    }

    .hospital::before {
        transform:  scale(1.2) translateX(1.5em)!important;
    }
    .remoteCare::before {
        transform:  scale(1.2) translateX(2.5em)!important;
    }
    .therapies::before {
        transform:  scale(1.2) translateX(2.5em)!important;
    }
    .mental::before {
        transform:  scale(1.2) translateX(1.5em)!important;
    }
    .dentalOptical::before {
        transform:  scale(1.2) translate(1em, -0.5em)!important;
    }

    .BenefitCard input, .BenefitCard i  {
        justify-self: start!important;
    }

    .consultations hgroup {
        transform: translate(1em, 15%);
    }

    .dentalOptical hgroup {
        transform: translateY(15%);
    }

    .BenefitCard hgroup * {
        text-align: center;
    }
}



.canToggle {
    cursor: pointer;
}

.BenefitCardOff::before {
    /*opacity: 0.3!important;*/
}


.BenefitCardOff {
    filter: grayscale(1);
    border: 3px dashed var(--peachy-green);
}
.BenefitCardOff>*:not(input) {
    /*opacity: 0.3;*/
}


.BenefitCard i {
    align-self: center;
    justify-self: center;
    display: block;
    font-size: 2em;
    color: var(--peachy-green);
}



.BenefitCard hgroup {
    display: flex;
    column-gap: 2em;
    align-items: center;
    justify-content: flex-start;
}

.BenefitCard input {
    justify-self: center;
}


.BenefitCard section {
    position: relative;
    grid-column-start: 2;
}

.BenefitCard fieldset {
    margin-left:1em;
    padding-top: 1em;
    border-top: 1px solid #ddd;
    grid-column: 1 / 3;
}



.BenefitCard section>p {
    margin-bottom: 0.5em;
}




.BenefitCard ul {
    line-height: initial;
    display: flex;
    flex-direction: column;
    /*row-gap: 1em;*/
    margin: 0 2em;
    list-style: disc;
}


.BenefitCard::before {
    content: ' ';
    grid-column-start: 1;
    grid-row-start: 2;
    justify-self: center;
    align-self: flex-start;
    background-position: center center!important;
    display: block;
    height: 6em;
    width: 8em;
    opacity: 0.8;
}

.consultations::before {
    background: url(../../../global-style/sticons/consultations-diagnostics.svg) no-repeat center 100%;
    background-size: cover;
    height: 4em;
}

.hospital::before {
    background: url(../../../global-style/sticons/hospital-care.svg) no-repeat center 80%;
    background-size: cover;
    height: 5em;
}

.remoteCare::before {
    background: url(../../../global-style/sticons/remote.svg) no-repeat center 100%;
    transform: translateX(0.5em);
    background-size: cover;
}


.therapies::before {
    background: url(../../../global-style/sticons/therapies.svg) no-repeat center 100%;
    background-size: cover;
    height: 5em;
}

.mental::before {
    background: url(../../../global-style/sticons/mental-health.svg) no-repeat center 70%;
    background-size: cover;
    height: 5em;
}

.dentalOptical::before {
    background: url(../../../global-style/sticons/optical-dental.svg) no-repeat center 120%;
    background-size: cover;
    height: 4em;
    transform: translateY(-0.25em);
}
