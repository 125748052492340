.CaptureOtherLife {
    background-color: white;
    border: 1px solid var(--neutral-dark);
    border-radius: 0.5em;
    padding: 2em 6em;
    width: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    filter: var(--modal-shadow);
    position:relative;
    margin: auto;
}

@media screen and (max-width: 600px) {
    .CaptureOtherLife {
        filter: none
    }
}


.CaptureOtherLife legend {
    width: 100%;
    text-align: center;
    font-family: var(--body-font) !important;
    font-size: 0.8em;
    padding-bottom: 1em;
}

.email {
    width: 100%;
}


@media screen and (max-width: 600px) {
    .CaptureOtherLife {
        width: 100%;
        padding: 1em;
        align-self: start;
        top:0;
        border: none;
        border-radius: 0;
        background-color: var(--paper-color);
    }
}


/* todo need to dry up the toggle styles*/
.optIns {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 1em;
    line-height: 1.5em;
    font-size: 0.75em;
}

.toggle {
    font-size: 0.6em;
}


.optIns input:focus {
    filter: var(--button-focus)
}


.optIns ul {
    list-style: disc;
    margin-left: 1em;
    line-height: 1.5em;
}




.cancel {
    align-self: flex-end;
    font-size: 0!important;
}

.cancel::before {
    position: absolute;
    right: 1em;
    font-size: 1.5rem;
}

