.HomePage {
    background-color: var(--neutral-color);
    display: flex;
    flex-direction: column;
    row-gap: 5em;
    overflow-x: hidden;
}

.FeelingPeachy {
    background-color: var(--peachy-pink);
    color: white;
}
.FeelingPeachy a:first-child {
    background-color: var(--peachy-pink-pale);
}
