.AboutUs::before {
    position: absolute;
    background: var(--neutral-color) url(../../global-style/svg-blobs/bolt-peachy-pink-light.svg) no-repeat top center;
    background-size: 100%;
    transform-origin: center top;
    transform: rotate(-5deg) scale(1.5);
    content: ' ';
    height:50%;
    width:100%;
    top:5em;
    z-index: -1;
}

.AboutUs {
    overflow: hidden;
    z-index: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex:1;
    gap: 1em;
    background-color: var(--neutral-color);
}



.heading h1 {
    width: 15em!important;
    max-width: 90vw;
}
.heading p {
    width: 30em!important;
    max-width: 90vw;
}

.FeelingPeachy::before {
    position: absolute;
    content: ' ';
    top: -110%;
    background-color: var(--peachy-pink);
    height:120%;
    width: 100%;
}

.FeelingPeachy {
    overflow: visible;
    z-index: -1;
    position: relative;
    color: white;
    width: 100%;
    padding-top: 15em;
    background-color: var(--peachy-pink);
}

.FeelingPeachy p {
    max-width: 90%;
}

.FeelingPeachy>nav>a:first-child {
    background-color: var(--peachy-pink-pale);
}

@media screen and (min-width: 1350px) {
    .AboutUs::before {
        transform: rotate(-5deg) scale(1.45);
    }
}

@media screen and (max-width: 900px){
    .AboutUs::before {
        background-size: 100%;
        transform-origin: top;
        top:4em!important;
        left:-17em!important;
        transform: rotate(-10deg) scale(3);
    }
}
