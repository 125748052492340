
.phoneContainer {
    display: grid;
    grid-template: auto / auto auto;
    align-items: baseline;
    justify-content: center;
    grid-gap: 0.5em;
}


.input {
    width: 100%;
}


.flag {
    font-size: 0.7em;
    /*background: url('../../../../../../App/css/svg/gb.svg') no-repeat left 70% / 2em auto;*/
    padding: 0.5em 0 0 2.5em;
    white-space: nowrap;
}



