.Speciality {
    border-radius: 0.5em;
    white-space: nowrap;
    border: 1px solid var(--peachy-purple-light);
    color: var(--text-color);
    padding : 0 1em;
    display: inline-block;
    /*transition: padding, font-size 0.2s;*/
}

.small {
    padding:0 0.5em;
    font-size: 0.8em;
}
