.DateInput {
    position: relative;
    display: flex;
    align-items: center;
}

.DateInput>button {
    position: absolute;
    right:-2.5rem;

}

.DateInput button:focus {
    filter: var(--button-focus)
}


@media screen and (max-width: 600px) {
    .DateInput {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 85%;
        padding:1em;
    }


}

.pickerIcon {
    font-size:0;
    color: var(--peachy-pink)
}

.pickerIcon::before {
    font-size:1.5rem;
}
