.AnyQuestions {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap:2em;
    padding-top: 4em!important;
    padding-bottom: 4em!important;
}


.AnyQuestions p {
    text-align: center;
    max-width: 25em;
}


.AnyQuestions nav {
    display:flex;
    column-gap: 1em;
}


.modalContent {
    background-color: white;
    border-radius: 2em;
    padding:1em;
    border: 2px solid rebeccapurple;
}
