.PageHeading {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    text-align: center;
    row-gap: 1em;
    padding: 4em 0;
}

.PageHeading>*:first-child  {
    width: 10em;
}

.PageHeading> p {
    width: 20em;
}
