

.TheEnd {
    background-color: var(--peachy-pink);
    color: white!important;
    row-gap: 10%;
    position: relative;
    padding-bottom: 0;
    justify-content: center;
}


.TheEnd article {
    row-gap: 0.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.TheEnd h1 {
    font-size: 4em;
    text-align: center;
}


.TheEnd button {
    margin:1em;
}


.instructions {
    text-align: center;
    width: 30em;
}

.remail {

}


.TheEnd span {
    font-size: 0.8em;
    /*margin-top: 2em;*/
    display: flex;
    align-items: center;
}

.TheEnd button {
    transition: transform 200ms;
    font-weight: 600;
    display: inline-block;
}

.TheEnd button:hover {
    transform: scale(1.05);
}


.exitButton {
    color: white!important;
    font-size: 2em;
}

.homeButton {
    /*margin-top: 10%;*/
}


.TheEnd input {
    color: black !important;
}


@media screen and (max-width: 600px) {
    .TheEnd {
        font-size: 0.8em;
    }
}
