.Container {
    position: relative;
    height:100%;
    display: inline;
    align-self: flex-start;
}


.ResultList {
    cursor: ns-resize;
    position: absolute;
    top:0;
    display: flex;
    flex-direction: column;
    height:100%;
    gap: 1em;
    /*border-top: 1px solid purple;*/
    padding-right: 4em!important;
    mask-image: linear-gradient(to bottom, transparent 0, black 1em, black calc(100% - 1em), transparent)
}
