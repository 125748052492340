.CaptureAddress fieldset  {
    display: flex;
    flex-direction: column;
}

.optIns {
    line-height: 1.5em;
    flex-direction: column;
    gap: 1em;
    overflow: visible;
    font-size: 0.8em;
}

.optIns label {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 1em;
}

.optIns label input:focus {
    filter: var(--button-focus)
}

.optIns ul {
    list-style: disc;
    margin-left: 1em;
    line-height: 1.5em;
    grid-column: 1/3;
}

.toggle {
    font-size: 0.6em!important;
}
