.invalidField input,
.invalidField select {
    border-color: var(--error);
}

.invalidField .message {
    color:  var(--error);
}

.warningField input, 
.warningField select {
    border-color: var(--warning);
}

.warningField .message {
    color:  var(--warning);
}

.message {
    position: absolute;
    bottom: -6px;
    right: 8px;
    font-size: small;
    padding-left: 2px;
    padding-right: 2px;
    background: white;
    white-space: nowrap;
}