.Header {
    font-size: 0.8em;
    z-index: 10;
    position: sticky;
    top: 0;
    padding: 1em 2em;
    background-color: white;
    height: var(--header-height);
    display: flex;
    align-items: center;
    justify-content: space-between;
    /*filter: var(--card-shadow);*/
}

.HeaderShadow {
    filter: var(--card-shadow);
}

/*.Header::after {*/
/*    display: block;*/
/*    position: absolute;*/
/*    top: 100%;*/
/*    left:0;*/
/*    content: ' ';*/
/*    width: 100%;*/
/*    height: 1.5vw;*/
/*    clip-path: ellipse(60% 100% at 50% -50% );*/
/*    background-color: white;*/
/*}*/


.Nav {
    flex:1;
}

.NavList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 3em;
}

.NavList li:first-child a {
    margin-right:3em;
}

.NavList li:hover a {
    color: var(--peachy-pink);
    text-shadow: 0 0 1px black;
}

.miniNav {
    font-size: 1.5em;
    background-color: white;
    padding: 1em 2em 1em 1em;
    border-radius: 0.5em;
    border: 1px solid var(--neutral-dark);
    position: absolute;
    display: flex;
    flex-direction: column;
    row-gap: 1em;
    align-items: flex-start;
    top:75%;
    left:0.5em;
    filter: var(--pullout-shadow);
    opacity:0;
    pointer-events: none;
    transition: all 0.3s;
    will-change: contents;
}

.miniNavOpen {
    pointer-events: all;
    opacity:1;
}


.miniNav>* {
    border-bottom: 2px solid transparent;
}


.miniNav a:hover {
    text-shadow: 0 0 1px black;
}

.miniNavActive {
    border-bottom: 2px solid var(--peachy-pink);
}


.HamburgerButton {
    position: absolute;
    background: url(../global-style/icons/icons-black/menu.svg) no-repeat center;
    background-size: 100%;
    display: block;
    height: 3em;
    width: 3em;
    left: 1em;
    overflow: visible;
    color: transparent;
}

.miniHeaderLogo {
    margin: auto;
    /*margin-left: 3em;*/
    width: 8.5em;
}




.pushRight {
    margin-left:auto;
}


/*@media screen and (max-width: 900px) {*/
/*    .NavList  {*/
/*        display: none;*/
/*    }*/
/*}*/
