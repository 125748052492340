
.ContactUs {
    z-index: 0;
    position: relative;
    background-color: var(--neutral-color);
    overflow-x: hidden;
}



.FeelingPeachy {
    position: relative;
    z-index: 0;
    background-color: var(--peachy-pink);
    color: white;
}

/*.FeelingPeachy::before {*/
/*    position: absolute;*/
/*    z-index: -1;*/
/*    height: 10em;*/
/*    width: 120%;*/
/*    background-color: var(--peachy-pink);*/
/*    content: ' ';*/
/*    top: 0;*/
/*    transform-origin: top right;*/
/*    transform: rotate(5deg);*/
/*}*/


.FeelingPeachy a:first-child {
    background-color: var(--peachy-pink-pale);
    border-color: var(--peachy-pink);
    color: white;
}


.pageHeading {
    padding-bottom: 2.5em;
}
