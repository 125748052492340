
.SeeAnyone {
    margin: 4em 0 8em;
}

.SeeAnyone article {
    justify-self: end;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 2em;
}


.SeeAnyone>article>h2::before {
    font-size: 1rem;
    position: absolute;
    content: ' ';
    height: 8em;
    width: 8em;
    top:-8em;
    right: -7em;
    background: url(../../../global-style/stickers/remote-sticker.svg) no-repeat center;
    transform: rotate(10deg);
    filter: var(--sticker-shadow);
}



@media screen and (max-width: 900px) {
    .SeeAnyone {
        row-gap: 3em;
        margin: 2em 0 12em;
    }
    .SeeAnyone article {
        justify-self: start;
        padding: 2em;
    }
    .SeeAnyone article>h2::before {
        font-size: 0.8rem!important;
    }
    .Photo {
        margin-right: 3em;
        justify-self: end;
        /*transform: rotate(7deg)!important;*/
    }
}

.SeeAnyone article h2 {
    position:relative;
    max-width: 10em;
}

.SeeAnyone article p {
    max-width: 25em;
}


.Photo {
    transform: rotate(5deg);
    background: url(./dr-dog.jpg) no-repeat 45% 60%;
    background-size: 180%;
}

