
.Label {
    text-align: left;
}

.Label span {
    z-index: 1;
    position: relative;
    height: 2em;
    line-height: 2.5em;
    transition: all 0.2s;
}

.Label.placeholder span {
    color: var(--neutral-dark);
    transform: translateY(2.2em) translateX(1.5em);
}

.Label.placeholder span::after {
    content: ''
}

.Label input {
    min-height: 2.5em;
}

.Label input,
.Label textarea {
    background-color: white;
    line-height: 2.5em;
    border: 2px solid var(--neutral-dark);
    border-radius: 0.5em;
    width: 100%;
    padding-left: 1em;
}

.Label input:focus,
.Label textarea:focus {
    /*filter: var(--card-shadow)*/
}


.TextAreaLabel {
    grid-column: 1 / 3;
}

.Label>textarea {
    min-height: 8em;
    resize: none;
    overflow: hidden;
}

