.FilterPanel {
    display: grid;
    align-items: center;
    /*grid-template: auto auto auto / 1fr 1fr ;*/
    grid-template: auto auto  / 1fr 1fr ;
    row-gap: 2em;
    column-gap: 4em;
    padding: 2em 2em 3em;
    line-height: 1em;
}


.FilterPanel>* {
    /*font-size: 0.8em;*/
    margin-bottom: 0.2em;
}


.FilterPanel button {
    justify-self: end;
}



@media screen and (max-width: 900px) {
    .FilterPanel {
        grid-template: auto / 1fr!important;
    }
}
