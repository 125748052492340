.App {
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-areas: 'HEADER' 'MAIN' 'FOOTER';
    margin: auto;
    min-height: var(--app-height);

    position: relative;

}

.App>header {
    grid-area: HEADER;
}

.App>main {
    grid-area: MAIN;
    color: var(--text-color);
    padding-top: var(--header-height);
}

.App>footer {
    grid-area: FOOTER;
}
