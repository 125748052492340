.Toggle {
    background-color: var(--neutral-dark);
    font-size: 0.8em;
    appearance: none;
    height:2em;
    min-width:3em;
    border-radius: 2em;
    display: flex;
    align-items: center;
    position: relative;
    transition: all 0.2s;
    cursor: pointer;
    border: 3px solid var(--neutral-dark);
    box-sizing: content-box;
}


.Toggle:checked {
    background-color: var(--peachy-green);
    border: 3px solid var(--peachy-green);
}


.Toggle::after {
    transition: all 0.2s;
    position: absolute;
    content: ' ';
    background-color: white;
    height:2em;
    width:2em;
    left: 0;
    border-radius: 2em;
    transform-origin: center;
}


.Toggle:checked::after {
    left: 100%;
    transform: translateX(-100%);
}


