.SimpleTerms {
    z-index: 1;
}

.SimpleTerms article {
    display: flex;
    row-gap: 1em;
    flex-direction: column;
    position: relative;
    padding: 0 2em;
}

.SimpleTerms article p {
    margin-bottom: 2em;
}

.SimpleTerms article p {
    margin-bottom: 2em;
}


.SimpleTerms figure {
    position: relative;
    transform: rotate(-5deg);
    background: url(./bubble-girl.jpg) no-repeat center;
    background-size: cover;
    margin-top: 4em;
}

@media screen and (min-width: 900px){
    .SimpleTerms article {
        align-self: start;
        margin-left: 15em;
    }
    .SimpleTerms article p:first-of-type {
        width: 22em;
    }
    .SimpleTerms figure {
        margin: 0;
    }
}
