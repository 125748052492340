.ClaimInAFewTaps::before {
    position: absolute;
    width: 100%;
    height:100%;
    z-index: -1;
    content: ' ';
    background: url(../../../global-style/svg-blobs/bolt-peachy-purple.svg) no-repeat top center;
    transform-origin: top center;
    will-change: transform;
    transform: translateX(3em) scaleX(-1) scale(2.5) rotate(-3deg);
}


.ClaimInAFewTaps {
    position: relative;
    color: white;
    z-index: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    /*row-gap: 1em;*/
    /*text-align: center;*/
}

.ClaimInAFewTaps>p {
    width: 18em;
    /*margin-bottom: 2em;*/
}


.ClaimInAFewTaps article {
    display: flex;
    text-align: center;
    flex-direction: column;
    row-gap: 1em;
    align-items: center;
    padding: 6em;
}


.ClaimInAFewTaps article>p {
    width: 18em;
}


.Steps {
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    counter-reset: claim-step-counter;
    text-align: center;
    list-style: none;
    row-gap: 2em;
}

.Steps>li {
    padding: 2em;
    position: relative;
    counter-increment: claim-step-counter;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1em;
    transition: all 0.3s;
}

.Steps>li>p {
    /*width: 22em;*/
    padding: 0 4em;
}

/*.Steps>li:hover *, .Steps>li:hover::after, .Steps>li:hover::before {*/
/*    transform: scale(1.1);*/
/*}*/


.Steps>li::before {
    font-family: var(--display-font);
    font-weight: 900;
    font-size: 1.5em;
    text-transform: uppercase;
    content: 'Step ' counter(claim-step-counter);
    color: var(--peachy-pink);
}

.Steps>li::after {
    /*position: absolute;*/
    /*left:-80%;*/
    /*top:0;*/
    width: 90%;
    content: ' ';
    height: 15em;
    filter: var(--pic-shadow);
}

.Steps>li:nth-child(1)::after {
    background: url(./OpenPeachy.png) no-repeat center;
    background-size: contain;
    height: 13.5em;
}
.Steps>li:nth-child(2)::after {
    background: url(./TellUs.png) no-repeat center;
    background-size: contain;
    /*left: 80%;*/
}
.Steps>li:nth-child(3)::after {
    background: url(./SnapClaim.png) no-repeat center;
    background-size: contain;
}



@media screen and (min-width: 900px) {

    .ClaimInAFewTaps::before {
        transform: scaleX(-1) scale(1.7) rotate(-3deg);
    }

    .Steps>li {
        width: 55%;
    }
    .Steps>li:nth-child(1) {
        transform: translate(5em, 0em);
    }

    .Steps>li:nth-child(1):hover {
        transform: translate(5em, 0em) scale(1.05);
    }

    .Steps>li:nth-child(2) {
        transform: translate(10em, -1em);
    }
    .Steps>li:nth-child(2):hover  {
        transform: translate(10em, -1em) scale(1.05);
    }

    .Steps>li:nth-child(3) {
        transform: translate(-6em, -2em);
    }
    .Steps>li:nth-child(3):hover  {
        transform: translate(-6em, -2em) scale(1.05);
    }

}
