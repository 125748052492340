
.TreeNode {
    display: flex;
    position: relative;
    flex-direction: column;
    line-height: 2em;
}


.TreeNode:not(:empty):before {
    position: absolute;
    content: ' ';
    height: 100%;
    width: 100%;
    border-left: 2px dashed lightgray;
    border-radius: 1em;
}


.TreeNode>li {
    padding: 0 0 0 0.2em ;
    position: relative;
    cursor: default;
    display: flex;
    flex-direction: column;
}


.TreeNode>li::before {
    position: absolute;
    pointer-events: none;
    left: 0.8em;
    content: '';
    display: inline;
}


.TreeNode dl {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 1em;
    padding-left: 1em;
    width: auto;
}

.TreeNode dt, .TreeNode dd {
    display: inline-block;
    margin-inline: 0;
}

.TreeNode dt {
    font-weight: normal;
}

.TreeNode li:first-child>dl {
    border-top-left-radius: 1em;
}

.TreeNode li:last-child>dl {
    border-bottom-left-radius: 1em;
}


.TreeNode dl:hover {
    background-color: aliceblue;
}



:global(.metaPressed) .TreeNode dl:hover {
    background-color: mistyrose;
    cursor: context-menu;
}

:global(.altPressed) .TreeNode dl:hover {
    background-color: lavender;
    cursor: grabbing;
}

:global(.shiftPressed) .TreeNode dl:hover {
    background-color: lightyellow;
    cursor: grabbing;
}



.TreeNode>li>ul {
    padding-left: 1.2em;
}

.TreeNode>li[data-open="true"] {
}

.TreeNode>li[data-open="true"]>dl {
    cursor: pointer;
}

.TreeNode>li[data-open="true"]>span:last-child {
    /*padding-left: 1em;*/
}

.TreeNode>li[data-open="false"]>dl {
    cursor: pointer;
}

.TreeNode>li[data-open="true"]::before {
    content: '';
}

.TreeNode>li[data-open="false"]::before {
    content: '';
}
