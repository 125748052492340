.Modal {
    position:fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top:0;
    left:0;
    overflow: scroll;
    height:var(--app-height);
    width:100%;
    z-index: 20;
}

.Modal > section {
    max-height: 70vh;
    overflow-y: scroll;
}


@media screen and (max-width: 600px) {
    .Modal>* {
        width: 100%;
        height: 100%;
        overflow: scroll;
        position: absolute;
    }
}
