.ModalPane {
    position: fixed;
    top: 0;
    left:0;
    width: 100vw;
    min-height: 100vh;
}

.ModalPaneCentered {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}


.ModalContentCentered {
    transform: translateX(-50%);
}


.ModalPane>* {
    width: fit-content;
}

.blur {
    background-color: #00000099;
    animation: blurAnimation 200ms ease-out forwards;
}

@keyframes blurAnimation {
    0% {
        backdrop-filter: blur(0);
    }
    100% {
        backdrop-filter: blur(0.1em);
    }
}
