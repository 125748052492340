
.distance::before {
    content: ' ';
    display: inline-block;
    width: 1.2em;
    height: 1.2em;
    background: url(../../../../../../global-style/icons/icons-black/location-pin.svg) no-repeat top right ;
    background-size: 1.5em;
}

.distance {
    white-space: nowrap;
    position: relative;
}
