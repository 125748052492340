.TextBox {
    width: 100%;
}

.TextBox input {
    border: 1.5px solid var(--neutral-dark);
    background-color: white;
    border-radius: 0.5em;
    padding: 0.5em 0.75em;
    width: 100%;
    line-height: 1em;
}

.TextBox span {
    display: none;
}

.TextBox input::placeholder {
    color: var(--neutral-dark)
}

.TextBox input:focus {
    border-color: var(--peachy-pink)
}
